import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useError } from 'helpers';
import { VEHICLE_FILTERS, VEHICLE_COLUMNS } from 'components/Users/helpers';
import { vehicleService, retranslationsService } from 'services';
import { Table, TableTypes, Input, Button, ButtonTypes, parseBrandModel } from '@bs/techconnect-ui';
import { ReactComponent as Search } from 'icons/search.svg';
import { ReactComponent as Clear } from 'icons/cross.svg';
import { ReactComponent as Add } from 'icons/plus-s.svg';
import { ReactComponent as Remove } from 'icons/minus.svg';

import styles from '../Retranslation.module.css';

export const RetranslationVehicles = ({ disabled, retranslationId }) => {
  const { getRetranslationVehicles, postVehiclesToRetranslation, deleteVehiclesFromRetranslation } =
    retranslationsService;

  const { setError } = useError();
  const [filters, setFilters] = useState({});
  const [filtersInput, setFiltersInput] = useState({});

  const [loading, setLoading] = useState(false);

  //ALL VEHICLES
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesPage, setVehiclesPage] = useState(1);
  const [vehiclesTotalPages, setVehiclesTotalPages] = useState(1);
  const [vehiclesCount, setVehiclesCount] = useState(0);
  //RETRANSLATION VEHICLES
  const [vehiclesR, setVehiclesR] = useState([]);
  const [vehiclesRPage, setVehiclesRPage] = useState(1);
  const [vehiclesRTotalPages, setVehiclesRTotalPages] = useState(1);
  const [vehiclesRCount, setVehiclesRCount] = useState(0);

  const fetchVehicle = useCallback(() => {
    vehicleService.getVehicles({ page: vehiclesPage, pageSize: 4, ...filters }).then(({ status, data }) => {
      if (status === 200 && Array.isArray(data?.items)) setVehicles(data.items);
      if (!isNaN(data.totalPages)) setVehiclesTotalPages(data.totalPages);
      if (!isNaN(data.count)) setVehiclesCount(data.count);
    });
  }, [filters, vehiclesPage]);

  const fetchVehicleR = useCallback(() => {
    getRetranslationVehicles(retranslationId, { page: vehiclesRPage, pageSize: 4, ...filters }).then(
      ({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setVehiclesR(data.items.map(v => ({ ...v, retranslation: true })));
        }
        if (!isNaN(data.totalPages)) setVehiclesRTotalPages(data.totalPages);
        if (!isNaN(data.count)) setVehiclesRCount(data.count);
      },
    );
  }, [getRetranslationVehicles, retranslationId, vehiclesRPage, filters, setVehiclesRCount]);

  useEffect(() => {
    if (retranslationId) fetchVehicle();
  }, [retranslationId, fetchVehicle, vehiclesPage]);

  useEffect(() => {
    if (retranslationId) fetchVehicleR();
  }, [retranslationId, fetchVehicleR, vehiclesRPage]);

  const applyFilters = () => {
    setVehiclesPage(1);
    setFilters(filtersInput);
  };
  const clearFilters = () => {
    setFiltersInput({});
    setFilters({});
  };
  const handleVehicle = ({ device, deviceId, retranslation }) => {
    if (!device?.deviceId && !deviceId) {
      setError('К автомобилю не привязано устройство');
      return;
    }
    setLoading(true);
    const method = retranslation ? deleteVehiclesFromRetranslation : postVehiclesToRetranslation;
    method(retranslationId, { devices: [device?.deviceId || deviceId] })
      .then(({ status }) => status === 204 && fetchVehicleR())
      .finally(() => setLoading(false));
  };

  const handler = row => ({
    ...row,
    action: (
      <Button variant={ButtonTypes.T} onClick={() => handleVehicle(row)} disabled={loading || disabled}>
        {row.retranslation ? <Remove /> : <Add />}
      </Button>
    ),
    vin: row.vin,
    brandModel: row.retranslation ? `${row.brand} ${row.model} ` : parseBrandModel(row.model),
  });

  return (
    <>
      <div className={styles['filters']}>
        {Object.keys(VEHICLE_FILTERS).map((key, index) => (
          <Input
            key={key}
            className={styles['filter-' + index]}
            placeholder={VEHICLE_FILTERS[key]}
            value={filtersInput[key] || ''}
            onChange={({ target }) => setFiltersInput({ ...filtersInput, [key]: target.value })}
            disabled={disabled || loading}
            onEnter={applyFilters}
          />
        ))}

        <Button disabled={disabled || loading} variant={ButtonTypes.S} onClick={applyFilters}>
          <Search />
        </Button>

        <Button
          variant={ButtonTypes.T}
          onClick={clearFilters}
          disabled={!Object.keys(filters).length || disabled || loading}
        >
          Сбросить фильтр&nbsp;
          <Clear />
        </Button>
      </div>

      <div className={styles['list']}>
        <div className={styles['list-column']}>
          <header>Все автомобили ({vehiclesCount})</header>
          <Table
            variant={TableTypes.S}
            columns={VEHICLE_COLUMNS}
            loading={loading}
            rows={vehicles}
            totalPages={vehiclesTotalPages}
            setPage={setVehiclesPage}
            page={vehiclesPage}
            idKey="vehicleId"
            handler={handler}
          />
        </div>

        <div className={styles['list-column']}>
          <header>В ретрансляции ({vehiclesRCount})</header>
          <Table
            variant={TableTypes.S}
            columns={VEHICLE_COLUMNS}
            loading={loading}
            rows={vehiclesR}
            totalPages={vehiclesRTotalPages}
            setPage={setVehiclesRPage}
            page={vehiclesRPage}
            idKey="vehicleId"
            handler={handler}
          />
        </div>
      </div>
    </>
  );
};

RetranslationVehicles.defaultProps = {
  disabled: false,
  retranslationId: '',
};

RetranslationVehicles.propTypes = {
  disabled: PropTypes.bool,
  retranslationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
