import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { VEHICLE_FILTERS, VEHICLE_COLUMNS } from 'components/Users/helpers';
import { vehicleService, accessGroupService } from 'services';
import { Table, TableTypes, Input, Button, ButtonTypes, parseBrandModel } from '@bs/techconnect-ui';
import { ReactComponent as Search } from 'icons/search.svg';
import { ReactComponent as Clear } from 'icons/cross.svg';
import { ReactComponent as Add } from 'icons/plus-s.svg';
import { ReactComponent as Remove } from 'icons/minus.svg';

import styles from '../AccessGroupsCreate.module.css';

export const VehicleList = ({ open, disabled, accessGroup, vehiclesAGCount, setVehiclesAGCount }) => {
  const [filters, setFilters] = useState({});
  const [filtersInput, setFiltersInput] = useState({});

  const [loading, setLoading] = useState(false);

  //ALL VEHICLES
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesPage, setVehiclesPage] = useState(1);
  const [vehiclesTotalPages, setVehiclesTotalPages] = useState(1);
  const [vehiclesCount, setVehiclesCount] = useState(0);
  //AG VEHICLES
  const [vehiclesAG, setVehiclesAG] = useState([]);
  const [vehiclesAGPage, setVehiclesAGPage] = useState(1);
  const [vehiclesAGTotalPages, setVehiclesAGTotalPages] = useState(1);

  const fetchVehicle = useCallback(() => {
    vehicleService.getVehicles({ page: vehiclesPage, pageSize: 8, ...filters }).then(({ status, data }) => {
      if (status === 200 && Array.isArray(data?.items)) setVehicles(data.items);
      if (!isNaN(data.totalPages)) setVehiclesTotalPages(data.totalPages);
      if (!isNaN(data.count)) setVehiclesCount(data.count);
    });
  }, [filters, vehiclesPage]);

  const fetchVehicleAG = useCallback(() => {
    accessGroupService
      .getAccessGroupVehicles(accessGroup, { page: vehiclesAGPage, pageSize: 8, ...filters })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) setVehiclesAG(data.items.map(v => ({ ...v, ag: true })));
        if (!isNaN(data.totalPages)) setVehiclesAGTotalPages(data.totalPages);
        if (!isNaN(data.count)) setVehiclesAGCount(data.count);
      });
  }, [accessGroup, filters, setVehiclesAGCount, vehiclesAGPage]);

  useEffect(() => {
    if (accessGroup) fetchVehicle();
  }, [accessGroup, fetchVehicle, vehiclesPage]);

  useEffect(() => {
    if (accessGroup) fetchVehicleAG();
  }, [accessGroup, vehiclesAGPage, fetchVehicleAG]);

  const applyFilters = () => {
    setVehiclesPage(1);
    setFilters(filtersInput);
  };
  const clearFilters = () => {
    setFiltersInput({});
    setFilters({});
  };
  const handleVehicle = ({ vehicleId, ag }) => {
    setLoading(true);
    const method = ag ? accessGroupService.deleteVehiclesFromAccessGroup : accessGroupService.postVehiclesToAccessGroup;
    method(accessGroup, { vehicles: [vehicleId] })
      .then(({ status }) => {
        if (status === 204) fetchVehicleAG();
      })
      .finally(() => setLoading(false));
  };

  const handler = row => ({
    ...row,
    action: (
      <Button variant={ButtonTypes.T} onClick={() => handleVehicle(row)} disabled={loading || disabled}>
        {row.ag ? <Remove /> : <Add />}
      </Button>
    ),
    vin: row.vin,
    brandModel: row.ag ? `${row.brand} ${row.model} ` : parseBrandModel(row.model),
  });

  if (!open) return null;

  return (
    <>
      <div className={styles['filters']}>
        {Object.keys(VEHICLE_FILTERS).map(key => (
          <Input
            key={key}
            placeholder={VEHICLE_FILTERS[key]}
            value={filtersInput[key] || ''}
            onChange={({ target }) => setFiltersInput({ ...filtersInput, [key]: target.value })}
            disabled={disabled || loading}
            onEnter={applyFilters}
          />
        ))}

        <Button disabled={disabled || loading} variant={ButtonTypes.S} onClick={applyFilters}>
          <Search />
        </Button>

        <Button
          variant={ButtonTypes.T}
          onClick={clearFilters}
          disabled={!Object.keys(filters).length || disabled || loading}
        >
          Сбросить фильтр&nbsp;
          <Clear />
        </Button>
      </div>

      <div className={styles['list']}>
        <div className={styles['list-column']}>
          <header>Все автомобили ({vehiclesCount})</header>
          <Table
            variant={TableTypes.S}
            columns={VEHICLE_COLUMNS}
            loading={loading}
            rows={vehicles}
            totalPages={vehiclesTotalPages}
            setPage={setVehiclesPage}
            page={vehiclesPage}
            idKey="vehicleId"
            handler={handler}
          />
        </div>

        <div className={styles['list-column']}>
          <header>В группе ({vehiclesAGCount})</header>
          <Table
            variant={TableTypes.S}
            columns={VEHICLE_COLUMNS}
            loading={loading}
            rows={vehiclesAG}
            totalPages={vehiclesAGTotalPages}
            setPage={setVehiclesAGPage}
            page={vehiclesAGPage}
            idKey="vehicleId"
            handler={handler}
          />
        </div>
      </div>
    </>
  );
};

VehicleList.defaultProps = {
  open: false,
  disabled: false,
  accessGroup: '',
  vehiclesAGCount: 0,
  setVehiclesAGCount: () => {},
};

VehicleList.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  accessGroup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vehiclesAGCount: PropTypes.number,
  setVehiclesAGCount: PropTypes.func,
};
