import { useAuth } from 'react-oidc-context';

import { useUser } from 'helpers';
import { Popper, Button, ButtonTypes } from '@bs/techconnect-ui';
import { ReactComponent as Exit } from 'icons/exit.svg';

import styles from './Header.module.css';

const Header = () => {
  const { clearStaleState, removeUser, signoutRedirect, user: oidcUser } = useAuth();
  const { user } = useUser();

  const signout = () => {
    const { id_token } = oidcUser || {};
    clearStaleState();
    removeUser();
    signoutRedirect({ id_token_hint: id_token });
  };

  return (
    <header className={styles['wrapper']}>
      <header>
        techconnect
        <b>&nbsp;Tooling Portal</b>
      </header>

      <div className={styles['profile']}>
        <span className={styles['profile-link']}>{user.email}</span>
        <Popper popup={'Выйти из профиля'}>
          <Button variant={ButtonTypes.T} onClick={signout}>
            <Exit />
          </Button>
        </Popper>
      </div>
    </header>
  );
};

export default Header;
