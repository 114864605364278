import { useEffect, useState, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { vehicleService } from 'services';
import { useSuccess } from 'helpers';
import { modelBrandService } from 'services/modelBrand.service';
import { DeviceList } from './DeviceList/DeviceList';
import { AlertModal } from 'components/AlertModal/AlertModal';
import {
  Modal,
  SelectDate,
  Select,
  Input,
  Button,
  ButtonTypes,
  requestDate,
  ERRORS,
  FIELD_VALIDATION,
  updateFormErrors,
} from '@bs/techconnect-ui';
import { ReactComponent as Close } from 'icons/cross.svg';

import styles from './VehicleCreate.module.css';

export const VehicleCreate = ({ open, setOpen }) => {
  const { setSuccess } = useSuccess();

  const [loadingModels, setLoadingModels] = useState(false);
  const [models, setModels] = useState([]);
  const [exitModal, setExitModal] = useState(false);

  const fetchModels = useCallback(() => {
    setLoadingModels(true);
    modelBrandService
      .getModels()
      .then(({ data, status }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setModels(data.items.map(m => ({ ...m, title: [m?.brand?.name, m?.name].join(' ') })));
        }
      })
      .finally(() => setLoadingModels(false));
  }, []);
  useEffect(() => {
    if (open) {
      fetchModels();
    }
  }, [fetchModels, open]);

  const {
    control,
    watch,
    reset,
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setError,
    setValue,
  } = useForm({ mode: 'onTouched' });
  const device = watch('deviceId');

  const create = form => {
    const saleDate = requestDate({ date: form.saleDate, tz: '+00:00' }) || null;
    vehicleService.createVehicle({ ...form, saleDate }).then(({ data, status }) => {
      if (status !== 200) updateFormErrors(data, form, setError);
      if (status === 200) {
        setSuccess(`Транспортное средство создано`);
        close();
      }
    });
  };

  const close = () => {
    reset({});
    setOpen();
    setExitModal(false);
  };

  return (
    <Modal open={open} className={styles['wrapper']}>
      <Button variant={ButtonTypes.T} className={styles['close']} onClick={isDirty ? () => setExitModal(true) : close}>
        <Close />
      </Button>
      <header className={styles['header']}>
        <h3>Создание транспортного средства</h3>
      </header>

      <div className={styles['params']}>
        <Controller
          control={control}
          name="modelId"
          rules={{ required: ERRORS.REQUIRED('Модель') }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <Select
              className={styles['params-field']}
              placeholder="Модель"
              list={models}
              selected={value}
              onSelect={onChange}
              onBlur={onBlur}
              error={error}
              disabled={loadingModels}
            />
          )}
        />
        <Input
          placeholder="VIN"
          className={styles['params-field']}
          register={register('vin', {
            required: ERRORS.REQUIRED('VIN'),
            validate: v => FIELD_VALIDATION.LENGTH(v, 17),
          })}
          error={errors.vin}
        />
        <Input
          placeholder="Гос. номер"
          className={styles['params-field']}
          register={register('plateNumber', {
            validate: v => FIELD_VALIDATION.DURATION(v, 0, 10),
          })}
          error={errors.plateNumber}
        />

        <Controller
          control={control}
          name="saleDate"
          render={({ field: { onChange, value } }) => (
            <SelectDate placeholder="Дата продажи" className={styles['params-field']} date={value} setDate={onChange} />
          )}
        />
      </div>

      <DeviceList selected={device} setSelected={setValue} />

      <footer className={styles['footer']}>
        <Button disabled={!isValid} onClick={() => handleSubmit(create)()}>
          Создать
        </Button>
      </footer>

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={close} />
    </Modal>
  );
};

VehicleCreate.defaultProps = {
  open: false,
  setOpen: () => {},
};

VehicleCreate.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
