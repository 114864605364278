import { trimObject } from '@bs/techconnect-ui';
import { createBaseApi, configRequest, prepareQuery } from './baseAPI';

const baseApi = createBaseApi();

export const vehicleService = {
  getVehicles,
  getVehicle,
  createVehicle,
  updateVehicle,
  updateVehicleModel,
  deleteVehicle,
  getVehicleOptionValues,
  createVehicleOptionValue,
  producedVehicles,
};

function getVehicles(params = {}) {
  return baseApi.get(`/vehicles?${prepareQuery(params)}`, configRequest);
}

function getVehicle(vehicleId) {
  return baseApi.get(`/vehicles/${vehicleId}`, configRequest);
}

function createVehicle(body) {
  return baseApi.post('/vehicles', trimObject(body), configRequest);
}

function updateVehicle(body, id) {
  return baseApi.put(`/vehicles/${id}`, trimObject(body), configRequest);
}

function updateVehicleModel(body, id) {
  return baseApi.put(`/vehicles/${id}/model`, trimObject(body), configRequest);
}

function deleteVehicle(id) {
  return baseApi.delete(`/vehicles/${id}`, configRequest);
}

function getVehicleOptionValues(id) {
  return baseApi.get(`/vehicles/${id}/optionvalues`, configRequest);
}

function createVehicleOptionValue(body, id) {
  return baseApi.post(`/vehicles/${id}/optionvalues`, trimObject(body), configRequest);
}

function producedVehicles(params = {}) {
  return baseApi.get(`/vehicles/produced?${prepareQuery(params)}`, configRequest);
}
