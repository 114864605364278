import { Controller, useForm } from 'react-hook-form';
import { saveAs } from 'save-as';
import PropTypes from 'prop-types';
import { addDays, subDays } from 'date-fns';

import {
  Button,
  ButtonTypes,
  ERRORS,
  Modal,
  Select,
  SelectDate,
  modelsOptionsMapper,
  requestDate,
} from '@bs/techconnect-ui';
import { dayInSec } from '../helpers';

import { modelBrandService, vehicleService } from 'services';
import { ReactComponent as Close } from 'icons/cross.svg';

import styles from './VehicleExport.module.css';

export const VehicleExport = ({ open, setOpen }) => {
  const { control, handleSubmit, reset, watch } = useForm({ mode: 'onTouched' });
  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const getMaxDate = date => {
    if (!date) return new Date();
    return addDays(date, 7) > new Date() ? new Date() : addDays(date, 7);
  };
  const isValidRange = (firstDate, secondDate) => {
    return (secondDate - firstDate) / dayInSec <= 7;
  };

  const create = form => {
    const { startDate, endDate, modelId = '' } = form;
    const dateFrom = requestDate({ date: startDate, tz: '+00:00' });
    const dateTo = requestDate({ date: endDate, tz: '+00:00' });
    vehicleService.producedVehicles({ dateFrom, dateTo, modelId }).then(({ data, status }) => {
      if (status === 200) {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, `${startDate}-${endDate}.csv`);
      }
    });
  };

  const close = () => {
    reset({});
    setOpen();
  };
  return (
    <Modal open={open} className={styles['wrapper']}>
      <header className={styles['header']}>
        <h3>Экспорт созданных ТС</h3>
      </header>
      <Button variant={ButtonTypes.T} className={styles['close']} onClick={close}>
        <Close />
      </Button>

      <Controller
        control={control}
        name="startDate"
        rules={{
          required: ERRORS.REQUIRED('Дата с'),
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <SelectDate
              placeholder="С"
              className={styles['params-field']}
              date={value || (endDate && subDays(endDate, 7)) || null}
              setDate={onChange}
              selectTime={true}
              dateFormat="dd.MM.yyyy HH:mm"
              minDate={endDate ? subDays(endDate, 7) : null}
              maxDate={endDate || new Date()}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="endDate"
        rules={{
          required: ERRORS.REQUIRED('Дата до'),
          validate: () => isValidRange(startDate, endDate) || 'Интервал между датами не должен превышать 7 дней',
        }}
        render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
          <SelectDate
            placeholder="До"
            className={styles['params-field']}
            date={value || (startDate && addDays(startDate, 7)) || null}
            setDate={onChange}
            selectTime={true}
            dateFormat="dd.MM.yyyy HH:mm"
            minDate={startDate || null}
            maxDate={startDate ? getMaxDate(startDate) : new Date()}
            error={error?.message}
            onBlur={onBlur}
          />
        )}
      />
      <Controller
        control={control}
        name="modelId"
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <Select
            className={styles['params-field']}
            placeholder={'модели'}
            source={modelBrandService.getModels}
            mapper={modelsOptionsMapper}
            selected={value}
            onSelect={onChange}
            onBlur={onBlur}
            error={error}
          />
        )}
      />
      <footer className={styles['footer']}>
        <Button onClick={handleSubmit(create)}>Получить данные</Button>
      </footer>
    </Modal>
  );
};

VehicleExport.defaultProps = {
  open: false,
  setOpen: () => {},
  updateList: () => {},
};

VehicleExport.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updateList: PropTypes.func,
};
