import { trimObject } from '@bs/techconnect-ui';
import { createBaseApi, configRequest, prepareQuery } from './baseAPI';

const baseApi = createBaseApi();

export const modelBrandService = {
  getBrands,
  getBrand,
  createBrand,
  updateBrand,
  deleteBrand,
  getBrandOptionValues,
  createBrandOptionValue,

  getModels,
  getModel,
  getModelsByBrand,
  createModel,
  updateModel,
  deleteModel,
  getModelOptionValues,
  createModelOptionValue,
  getInitialConfig,
  updateInitialConfig,
};

function getBrands(params = {}) {
  return baseApi.get(`/brands?${prepareQuery(params)}`, configRequest);
}
function getBrand(id) {
  return baseApi.get(`/brands/${id}`, configRequest);
}

function createBrand(body) {
  return baseApi.post('/brands', trimObject(body), configRequest);
}
function updateBrand(body, id) {
  return baseApi.put(`/brands/${id}`, trimObject(body), configRequest);
}
function deleteBrand(id) {
  return baseApi.delete(`/brands/${id}`, configRequest);
}
function getBrandOptionValues(id) {
  return baseApi.get(`/brands/${id}/optionvalues`, configRequest);
}
function createBrandOptionValue(body, id) {
  return baseApi.post(`/brands/${id}/optionvalues`, trimObject(body), configRequest);
}

function getModels() {
  return baseApi.get('/models', configRequest);
}

function getModel(id) {
  return baseApi.get(`/models/${id}`, configRequest);
}

function getModelsByBrand(brandId) {
  return baseApi.get(`/brands/${brandId}/models`, configRequest);
}
function createModel(body) {
  return baseApi.post('/models', trimObject(body), configRequest);
}
function updateModel(body, id) {
  return baseApi.put(`/models/${id}`, trimObject(body), configRequest);
}
function deleteModel(id) {
  return baseApi.delete(`/models/${id}`, configRequest);
}
function getModelOptionValues(id) {
  return baseApi.get(`/models/${id}/optionvalues`, configRequest);
}
function createModelOptionValue(body, id) {
  return baseApi.post(`/models/${id}/optionvalues`, trimObject(body), configRequest);
}

function getInitialConfig(id) {
  return baseApi.get(`/models/${id}/initialconfig`, configRequest);
}

function updateInitialConfig(body, id) {
  return baseApi.put(`/models/${id}/initialconfig`, trimObject(body), configRequest);
}
