import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { optionService } from 'services';
import { COLUMNS } from './helpers';

import { Popper, Table, Button, ButtonTypes, ListFilters, pageSize } from '@bs/techconnect-ui';
import { OptionsCreate } from './OptionsCreate/OptionsCreate';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import styles from './Options.module.css';

const Options = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const fetchOptions = useCallback(() => {
    setLoading(true);
    optionService
      .getOptions({ page, pageSize })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(
            data.items.map((option = {}) => {
              const name = option?.name;
              const key = option?.key;
              const type = option?.type;
              return { ...option, name, key, type };
            }),
          );
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const handler = row => ({
    ...row,
    optionName: row.name,
    optionKey: row.key,
    optionType: row.type,
    edit: (
      <Popper popup={'Редактировать'}>
        <Button
          variant={ButtonTypes.TL}
          onClick={() => {
            setEditModal(row);
          }}
        >
          <Edit />
        </Button>
      </Popper>
    ),
    delete: (
      <Popper popup={'Удалить'}>
        <Button variant={ButtonTypes.TL} onClick={() => setDeleteModal(row.id)}>
          <Delete />
        </Button>
      </Popper>
    ),
  });

  return (
    <>
      <ListFilters title="Oпции" className={styles['header']} CreateModal={OptionsCreate} updateList={fetchOptions} />

      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        loading={loading}
        idKey="id"
        handler={handler}
      />

      <OptionsCreate
        option={editModal}
        open={!!editModal}
        setOpen={() => setEditModal(!editModal)}
        updateList={fetchOptions}
      />

      <ConfirmModal
        open={deleteModal}
        setOpen={() => setDeleteModal(!deleteModal)}
        updateList={fetchOptions}
        method={() => optionService.deleteOption(deleteModal)}
        header="Удалить опцию?"
        description="Я понимаю, что восстановить опцию будет невозможно"
      />
    </>
  );
};

export default Options;
