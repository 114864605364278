import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { optionService } from 'services';
import { OPTIONS_LIST_ENUM, useSuccess } from 'helpers';
import { OPTION_TYPE_LIST } from '../helpers';
import { AlertModal } from 'components/AlertModal/AlertModal';
import {
  Modal,
  Select,
  Input,
  Button,
  ButtonTypes,
  ERRORS,
  FIELD_VALIDATION,
  updateFormErrors,
} from '@bs/techconnect-ui';
import { ReactComponent as Close } from 'icons/cross.svg';
import { ReactComponent as Add } from 'icons/plus.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import styles from '../Options.module.css';

export const OptionsCreate = ({ option, open, setOpen, updateList }) => {
  const { setSuccess } = useSuccess();

  const [newOption, setNewOption] = useState('');
  const [exitModal, setExitModal] = useState(false);

  const {
    control,
    reset,
    watch,
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setError,
    setValue,
  } = useForm({ mode: 'onTouched' });
  const typeValue = watch('type');
  register('allowedValues');
  const allowedValue = watch('allowedValues') || [];
  useEffect(() => {
    reset(option);
  }, [option, reset]);

  const disabled = !newOption || newOption.trim().length === 0 || newOption.length > 30;
  const errorMessage = { message: ERRORS.DURATION(1, 30) };

  const close = () => {
    reset({});
    setOpen();
    setExitModal(false);
  };

  const create = form => {
    const { id, name, key, type, allowedValues } = form;
    const validData = type === OPTIONS_LIST_ENUM.LIST ? allowedValues || [] : [];
    const method = id ? optionService.updateOption : optionService.createOption;
    method({ name, key, type, allowedValues: validData }, id).then(({ data, status }) => {
      if (status !== 200) updateFormErrors(data, form, setError);
      if (status === 200) {
        setSuccess(`${id ? 'Изменения сохранены' : 'Опция создана'} `);
        updateList();
        close();
      }
    });
  };

  return (
    <Modal open={open} className={styles['create-wrapper']}>
      <header>{option?.id ? 'Редактирование' : 'Создание'} опции</header>
      <Button
        variant={ButtonTypes.T}
        className={styles['create-close']}
        onClick={isDirty ? () => setExitModal(true) : close}
      >
        <Close />
      </Button>

      <Input
        placeholder="Название"
        className={styles['create-field']}
        register={register('name', {
          required: ERRORS.REQUIRED('Название'),
          validate: v => FIELD_VALIDATION.DURATION(v, 1, 200),
        })}
        error={errors.name}
      />
      <Input
        placeholder="Ключ"
        className={styles['create-field']}
        register={register('key', {
          required: ERRORS.REQUIRED('Ключ'),
          validate: v => FIELD_VALIDATION.DURATION(v, 1, 200),
        })}
        error={errors.key}
      />
      <Controller
        control={control}
        name="type"
        rules={{ required: ERRORS.REQUIRED('optionType') }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <Select
            className={styles['create-field']}
            placeholder="Тип значения"
            list={OPTION_TYPE_LIST}
            selected={value}
            onSelect={onChange}
            onBlur={onBlur}
            error={error}
          />
        )}
      />
      {typeValue === OPTIONS_LIST_ENUM.LIST && (
        <>
          <h4>Допустимые значения</h4>
          <div className={styles['created-field']}>
            <div className={styles['add-fields']}>
              <Input
                placeholder="Название значения"
                className={styles['create-value']}
                value={newOption}
                onChange={e => setNewOption(e.target.value)}
                error={newOption.length > 30 && errorMessage}
                customWidth={true}
              />
              <Button
                variant={ButtonTypes.S}
                disabled={disabled}
                onClick={() => {
                  setValue('allowedValues', allowedValue ? [...allowedValue, newOption] : [newOption]);
                  setNewOption('');
                }}
              >
                <Add />
              </Button>
            </div>
            {allowedValue?.map((elem, currentIndex) => (
              <div key={currentIndex + elem} className={styles['created-value']}>
                <span>{elem}</span>
                <Button
                  key={currentIndex}
                  variant={ButtonTypes.T}
                  onClick={() =>
                    setValue(
                      'allowedValues',
                      allowedValue.filter((field, indexField) => indexField !== currentIndex),
                    )
                  }
                >
                  <Delete />
                </Button>
              </div>
            ))}
          </div>
        </>
      )}

      <footer>
        <Button onClick={() => handleSubmit(create)()}>{option.id ? 'Редактировать' : 'Создать'}</Button>
      </footer>

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={close} />
    </Modal>
  );
};

OptionsCreate.defaultProps = {
  option: {},
  open: false,
  setOpen: () => {},
  updateList: () => {},
};

OptionsCreate.propTypes = {
  option: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updateList: PropTypes.func,
};
