import { trimObject } from '@bs/techconnect-ui';

const { createBaseApi, configRequest } = require('./baseAPI');

const baseApi = createBaseApi();

export const vehicleCommand = {
  engineStart,
  engineStop,
  authorize,
  unauthorize,
  lock,
  unlock,
  panic,
  getMetaData,
  initialConfig,
  accelCalibration,
  tailgateOpen,
  tailgateClose,
  getCommandStatus,
};

export function getVehicleMobile(vehicleId) {
  return baseApi.get(`/mobile/vehicles/${vehicleId}`, configRequest);
}
function getCommandStatus(commandId) {
  return baseApi.get(`/commands/${commandId}`, configRequest);
}

function engineStart(body) {
  return baseApi.post(`/commands/engineStart`, trimObject(body), configRequest);
}
function engineStop(body) {
  return baseApi.post(`/commands/engineStop`, trimObject(body), configRequest);
}
function accelCalibration(body) {
  return baseApi.post(`/commands/accelCalibration`, trimObject(body), configRequest);
}
function initialConfig(body) {
  return baseApi.post(`/commands/initialConfig`, trimObject(body), configRequest);
}
function authorize(body) {
  return baseApi.post(`/commands/authorize`, trimObject(body), configRequest);
}
function unauthorize(body) {
  return baseApi.post(`/commands/unauthorize`, trimObject(body), configRequest);
}
function lock(body) {
  return baseApi.post(`/commands/lock`, trimObject(body), configRequest);
}
function unlock(body) {
  return baseApi.post(`/commands/unlock`, trimObject(body), configRequest);
}
function panic(body) {
  return baseApi.post(`/commands/panic`, trimObject(body), configRequest);
}
function tailgateOpen(body) {
  return baseApi.post(`/commands/tailgateOpen`, trimObject(body), configRequest);
}
function tailgateClose(body) {
  return baseApi.post(`/commands/tailgateClose`, trimObject(body), configRequest);
}
function getMetaData(body) {
  return baseApi.post(`/commands/getMetadata`, trimObject(body), configRequest);
}
