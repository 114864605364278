import { trimObject } from '@bs/techconnect-ui';

const { createBaseApi, configRequest, prepareQuery } = require('./baseAPI');

const baseApi = createBaseApi();

export const optionService = { getOptions, createOption, updateOption, deleteOption };

function getOptions(params = {}) {
  return baseApi.get(`/options?${prepareQuery(params)}`, configRequest);
}

function createOption(body) {
  return baseApi.post('/options', trimObject(body), configRequest);
}

function updateOption(body, id) {
  return baseApi.put(`/options/${id}`, trimObject(body), configRequest);
}

function deleteOption(id) {
  return baseApi.delete(`/options/${id}`, configRequest);
}
