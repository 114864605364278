import { useEffect, useState } from 'react';
import { saveAs } from 'save-as';
import PropTypes from 'prop-types';

import { telemetryService } from 'services';
import { TELEMETRY_COLUMN_1, TELEMETRY_COLUMN_2 } from '../helpers';
import { Modal, Slider, Button, ButtonTypes, useTelemetry } from '@bs/techconnect-ui';
import { ReactComponent as Close } from 'icons/cross.svg';

import styles from './VehicleTelemetry.module.css';

export const VehicleTelemetry = ({ deviceId, vehicleId, setOpen }) => {
  const [telemetry, setTelemetry] = useState([]);
  const [stopCollect, setStopCollect] = useState(false);
  const { stateData } = useTelemetry(deviceId, vehicleId, telemetryService.subscribe, telemetryService.unsubscribe);

  useEffect(() => {
    if (!stopCollect && !!Object.keys(stateData).length) {
      setTelemetry(prev => {
        if (prev.length > 199) return [...prev.slice(0, -1), stateData];
        else return [...prev, stateData];
      });
    }
  }, [stateData, stopCollect]);

  const [selected, setSelected] = useState(0);
  const current = telemetry[selected - 1] || {};
  const [fixLast, setFixLast] = useState(true);
  useEffect(() => {
    if (telemetry.length && fixLast) setSelected(telemetry.length);
  }, [fixLast, telemetry.length]);

  const saveData = () => {
    const blob = new Blob([JSON.stringify(telemetry)], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'telemetry.txt');
  };

  return (
    <Modal open={!!deviceId} className={styles['wrapper']}>
      <Button variant={ButtonTypes.T} onClick={setOpen} className={styles['close']}>
        <Close />
      </Button>

      <header>
        <div className={styles['header-title']}>Телеметрия</div>
        <Button variant={stopCollect ? ButtonTypes.P : ButtonTypes.B} onClick={() => setStopCollect(!stopCollect)}>
          {stopCollect ? 'Запустить' : 'Остановить'}
        </Button>
      </header>

      <div className={styles['info']}>
        <div className={styles['info-ids']}>
          <div>
            <span>VehicleID:</span> {vehicleId}
          </div>
          <div>
            <span>DeviceID:</span> {deviceId}
          </div>
        </div>
        <div className={styles['info-count']}>
          <div className={styles['count']}>{telemetry.length}</div>
          <span>пакетов получено</span>
        </div>
      </div>

      <Slider
        value={selected}
        onChange={v => {
          setSelected(v);
          setFixLast(v === telemetry.length);
        }}
        min={telemetry.length ? 1 : 0}
        max={telemetry.length}
      />

      <div className={styles['data']}>
        {[TELEMETRY_COLUMN_1, TELEMETRY_COLUMN_2].map((column, index) => (
          <div key={index} className={styles['column']}>
            {Object.keys(column).map(key => (
              <div key={'telemetry-' + key} className={styles['row']}>
                <div className={styles['key']}>{key}</div>
                <div>{column[key](current[key], current)}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={styles['actions']}>
        <Button onClick={saveData}>Сохранить данные (JSON)</Button>
        <Button
          variant={ButtonTypes.B}
          onClick={() => {
            setTelemetry([]);
            setSelected(0);
          }}
        >
          Очистить данные
        </Button>
      </div>
    </Modal>
  );
};

VehicleTelemetry.defaultProps = {
  deviceId: '',
  vehicleId: '',
  setOpen: () => {},
};

VehicleTelemetry.propTypes = {
  deviceId: PropTypes.string,
  vehicleId: PropTypes.string,
  setOpen: PropTypes.func,
};
