import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { modelBrandService, optionValuesService } from 'services';
import {
  INITCONFIG_FIELDS,
  MODEL_FIELDS,
  OPTIONS_VALUES_COLUMNS,
  SETTINGS_TABS,
  getFormData,
  requiredFieldKeys,
} from '../helpers';
import { useSuccess } from 'helpers';
import {
  Button,
  ButtonGroup,
  ButtonTypes,
  ERRORS,
  FIELD_VALIDATION,
  Input,
  Paper,
  Popper,
  Select,
  Table,
  updateFormErrors,
} from '@bs/techconnect-ui';
import { OptionsValuesForm } from '../OptionsValuesForm/OptionsValuesForm';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from './BrandsModelsForm.module.css';

const BrandSModelsForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const entity = pathname.split('/')[2];
  const isBrand = entity === 'brand';
  const isModel = entity === 'model';
  const isCreateBrand = id === 'newBrand';
  const isCreateModel = pathname.split('/')[4] === 'newModel';
  const isCreate = isCreateBrand || isCreateModel;

  const {
    createBrand,
    updateBrand,
    getBrand,
    getBrandOptionValues,
    createBrandOptionValue,
    createModel,
    updateModel,
    getModelOptionValues,
    createModelOptionValue,
    getModel,
    getInitialConfig,
    updateInitialConfig,
  } = modelBrandService;

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(SETTINGS_TABS.p);
  const [optionsValues, setOptionsValues] = useState([]);
  const [loadingOptionsValues, setLoadingOptionsValues] = useState(false);
  const [optionValueForm, setOptionValueForm] = useState(false);
  const [deleteModal, setDeleteModal] = useState('');
  const [exitModal, setExitModal] = useState(false);
  const [alertAction, setAlertAction] = useState(null);
  const [preparedOptions, setPreparedOptions] = useState([]);

  const { setSuccess } = useSuccess();

  const isParams = tab === SETTINGS_TABS.p;
  const isOption = tab === SETTINGS_TABS.o;
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isDirty },
    reset,
    setError,
    control,
    trigger,
  } = useForm({ mode: 'onTouched' });

  // получение данных бренда/модели для редактирования
  useEffect(() => {
    if (!isCreateBrand && !isCreateModel && isParams) {
      setLoading(true);
      const method = isModel ? getModel : getBrand;
      method(id).then(({ status, data }) => {
        if (status === 200 && data) {
          getConfig(data);
        } else {
          setLoading(false);
        }
      });
    }
    // eslint-disable-next-line
  }, [isCreateBrand, reset, id, isParams]);

  // получение списка опций для бренда/модели
  const fetchOptionsValues = useCallback(() => {
    setLoadingOptionsValues(true);
    const method = isModel ? getModelOptionValues : getBrandOptionValues;
    method(id)
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setOptionsValues(data?.items);
        }
      })
      .finally(() => setLoadingOptionsValues(false));
  }, [getBrandOptionValues, getModelOptionValues, id, isModel]);

  useEffect(() => {
    if (!isCreateBrand && !isCreateModel && isOption) {
      fetchOptionsValues();
    }
  }, [fetchOptionsValues, isCreateBrand, isOption, isCreateModel]);

  // получение initConfig для модели
  const getConfig = form => {
    if (isModel) {
      getInitialConfig(id)
        .then(({ status, data }) => {
          if (status === 200 && data) {
            const { homeEndpointsValues, eventThresholds, ...rest } = data || {};
            const initConfigData = { ...homeEndpointsValues, ...eventThresholds, ...rest };
            reset({ ...form, ...initConfigData });
          }
        })
        .finally(() => setLoading(false));
    } else {
      reset(form);
      setLoading(false);
    }
  };

  // создание/редактирование бренда
  const submitBrand = form => {
    const method = isCreateBrand ? createBrand : updateBrand;
    setLoading(true);
    method(form, id)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          if (!isCreateBrand) {
            setSuccess('Изменения сохранены');
          } else {
            if (preparedOptions.length) {
              const promises = preparedOptions.map(({ id, value }) =>
                createBrandOptionValue({ value, optionId: id }, data?.id),
              );
              Promise.all(promises).then(results => {
                if (results.every(result => result.status === 200)) {
                  setSuccess('Бренд создан');
                  navigate(`/brandsModels`);
                }
              });
            } else {
              setSuccess('Бренд создан');
              navigate(`/brandsModels`);
            }
          }
        }
      })
      .finally(() => {
        setLoading(false);
        reset(form);
      });
  };

  //создание/редактирование модели
  const submitModel = form => {
    const { modelBody, initConfigBody } = getFormData(form);
    setLoading(true);
    if (!isCreateModel) {
      updateModel(modelBody, id)
        .then(({ status }) => {
          if (status === 200) {
            setSuccess('Изменения сохранены');
            if (Object.keys(initConfigBody).length) {
              updateInitialConfig(initConfigBody, id).then(({ status }) => {
                if (status === 200) {
                  setSuccess('Параметры Initial Config сохранены');
                }
              });
            }
          }
        })
        .finally(() => setLoading(false));
    } else {
      createModel({ ...modelBody, brandId: id }, id)
        .then(({ data, status }) => {
          if (status !== 200) updateFormErrors(data, form, setError);
          if (status === 200) {
            setSuccess('Модель создана');
            const { id: modelId } = data;
            if (Object.keys(initConfigBody).length) {
              updateInitialConfig(initConfigBody, modelId).then(({ status }) => {
                if (status === 200) {
                  setSuccess('Параметры Initial Config сохранены');
                }
              });
            }
            const promises = [];
            if (preparedOptions.length) {
              const optionPromises = preparedOptions.map(({ id, value }) =>
                createModelOptionValue({ value, optionId: id }, modelId),
              );
              promises.push(optionPromises);
            }
            Promise.all(promises).then(results => {
              if (results.every(result => result.status === 200)) {
                setSuccess('Опции добавлены');
              }
            });
            navigate(`/brandsModels`);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const handler = row => ({
    ...row,
    name: isCreate ? row?.name : row?.option?.name,
    value: row.value,
    edit: (
      <Popper popup={`Редактировать опцию`}>
        <Button variant={ButtonTypes.TL} onClick={() => setOptionValueForm(row)}>
          <Edit />
        </Button>
      </Popper>
    ),
    delete: (
      <Popper popup={`Удалить опцию`}>
        <Button variant={ButtonTypes.TL} onClick={() => setDeleteModal(row.id)}>
          <Delete />
        </Button>
      </Popper>
    ),
  });

  const deleteOptionHandler = id => {
    setPreparedOptions(prev => prev.filter(option => option.id !== id));
    setDeleteModal('');
  };

  const back = () => {
    reset({});
    setExitModal(false);
    navigate(`/brandsModels`);
  };

  const toggle = () => {
    reset({});
    setTab(SETTINGS_TABS.o);
    setExitModal(false);
  };

  const isFieldsDirty = isCreate ? false : isDirty;

  const handlerBack = () => {
    setAlertAction('back');
    setExitModal(true);
  };

  const handlerBG = isValid
    ? isFieldsDirty && isParams
      ? () => {
          setAlertAction('toggle');
          setExitModal(true);
        }
      : setTab
    : () => trigger(requiredFieldKeys);

  const getModelParamsFields = (fieldsArray, register, control) => {
    return fieldsArray.map(({ key, title, options, list, type }) =>
      !list ? (
        <Input
          key={key}
          className={styles['field']}
          label={title}
          register={register(key, options)}
          placeholder=" "
          type={type}
          error={errors[key]}
        />
      ) : (
        <Controller
          key={key}
          control={control}
          name={key}
          rules={options}
          render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
            return (
              <Select
                className={styles['field']}
                label={title}
                list={list}
                selected={value?.toString()}
                onBlur={onBlur}
                error={error}
                onSelect={onChange}
              />
            );
          }}
        />
      ),
    );
  };
  return (
    <>
      <Paper className={styles['paper']}>
        <header className={styles['header']}>
          <Button className={styles['back-btn']} variant={ButtonTypes.SR} onClick={isDirty ? handlerBack : back}>
            <Back />
          </Button>
          <h3>
            {isCreate ? 'Создание ' : 'Редактирование '}
            {isModel || isCreateModel ? 'модели' : 'бренда'}
          </h3>
          <Button
            className={styles['submit-btn']}
            disabled={!isValid || loading}
            onClick={() => handleSubmit(isBrand && !isCreateModel ? submitBrand : submitModel)()}
          >
            {isCreate ? `Создать ${isCreateModel ? 'модель' : 'бренд'}` : 'Сохранить изменения'}
          </Button>
        </header>
        <div className={styles['btn-wrapper']}>
          <ButtonGroup
            buttons={[
              { key: SETTINGS_TABS.p, title: 'Параметры' },
              { key: SETTINGS_TABS.o, title: 'Опции' },
            ]}
            selected={[tab]}
            onSelect={handlerBG}
            className={styles['toggle-btn']}
          />
          {isOption && (
            <Button className={styles['add-option']} variant={'border'} onClick={() => setOptionValueForm({})}>
              Добавить опцию
            </Button>
          )}
        </div>
      </Paper>
      <div className={styles['body']}>
        {isParams && !isModel && !isCreateModel && (
          <Input
            className={styles['name']}
            label="Название бренда"
            placeholder=" "
            register={register('name', {
              required: ERRORS.REQUIRED('Название бренда'),
              validate: v => FIELD_VALIDATION.DURATION(v, 1, 30),
            })}
            error={errors.name}
            disabled={loading}
          />
        )}
        {isParams && (isModel || isCreateModel) && (
          <>
            {isModel && <h3>ModelId: {id}</h3>}
            <div className={styles['model-fields']}>
              {MODEL_FIELDS.map(({ key, title, options }) => (
                <Input
                  key={key}
                  className={styles['field']}
                  label={title}
                  register={register(key, options)}
                  placeholder=" "
                  error={errors[key]}
                />
              ))}
            </div>
            <div className={styles['init-config-container']}>
              <h3>Initial Config</h3>
              {INITCONFIG_FIELDS.map((fieldsArray, index) => (
                <div key={index} className={styles['fields-group']}>
                  {getModelParamsFields(fieldsArray, register, control)}
                </div>
              ))}
            </div>
          </>
        )}
        {isOption && (
          <Table
            idKey="id"
            className={styles['options-values-table']}
            columns={OPTIONS_VALUES_COLUMNS}
            handler={handler}
            loading={loadingOptionsValues}
            rows={isCreate ? preparedOptions : optionsValues}
          />
        )}
        {optionValueForm && (
          <OptionsValuesForm
            itemId={id}
            optionValue={optionValueForm}
            open={!!optionValueForm}
            createMethod={isModel ? createModelOptionValue : createBrandOptionValue}
            setOpen={() => setOptionValueForm(false)}
            updateList={fetchOptionsValues}
            isCreateEntity={isCreateBrand || isCreateModel}
            preparedOptions={preparedOptions}
            setPreparedOptions={setPreparedOptions}
          />
        )}
        <ConfirmModal
          open={!!deleteModal}
          setOpen={() => setDeleteModal(!deleteModal)}
          updateList={fetchOptionsValues}
          method={() => optionValuesService.deleteOptionValue(deleteModal)}
          header={'Удаление значения опции'}
          description="Вы уверены что хотите удалить?"
          actionHandler={isCreate ? () => deleteOptionHandler(deleteModal) : false}
        />
      </div>
      <AlertModal
        open={exitModal}
        setOpen={() => setExitModal(!exitModal)}
        action={alertAction === 'back' ? back : toggle}
      />
    </>
  );
};
export default BrandSModelsForm;
