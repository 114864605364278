import { useAuth } from 'react-oidc-context';
import { Button, ButtonTypes, turnOffEvent } from '@bs/techconnect-ui';
import { ReactComponent as Logo } from 'icons/logo.svg';

import styles from './Login.module.css';

export const Login = () => {
  const { signinRedirect, isLoading } = useAuth();

  const handleLogin = e => {
    turnOffEvent(e);
    signinRedirect();
  };

  return (
    <div className={styles['wrapper']}>
      <Logo className={styles['logo']} />
      <header>
        techconnect
        <br />
        <b>Tooling Portal</b>
      </header>
      <span>Авторизация</span>

      <Button variant={ButtonTypes.PXL} disabled={isLoading} onClick={handleLogin}>
        Войти
      </Button>
    </div>
  );
};
