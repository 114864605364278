import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { retranslationsService } from 'services';
import { useSuccess } from 'helpers';
import { PROTOCOLS } from '../helpers';
import { RetranslationVehicles } from './RetranslationVehicles/RetranslationVehicles';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { Loader, Button, ButtonTypes, Input, ERRORS, Select, TextArea, updateFormErrors } from '@bs/techconnect-ui';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from './Retranslation.module.css';

export const Retranslation = () => {
  const { retranslationId } = useParams();
  const isCreate = retranslationId === 'new';
  const { setSuccess } = useSuccess();
  const navigate = useNavigate();

  const { getRetranslation, createRetranslation, updateRetranslation } = retranslationsService;

  const [loading, setLoading] = useState(false);
  const [exitModal, setExitModal] = useState(false);

  const {
    control,
    register,
    reset,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setError,
  } = useForm({ mode: 'onTouched' });

  useEffect(() => {
    if (!isCreate) {
      setLoading(true);
      getRetranslation(retranslationId)
        .then(({ status, data }) => {
          if (status === 200) reset(data);
        })
        .finally(() => setLoading(false));
    }
  }, [reset, isCreate, retranslationId, getRetranslation]);

  const submit = form => {
    const method = isCreate ? () => createRetranslation(form) : () => updateRetranslation(form, retranslationId);
    setLoading(true);
    method()
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          setSuccess(isCreate ? 'Ретрансляция создана' : 'Изменения сохранены');
          if (isCreate) navigate(`/retranslations/${data.id}`);
        }
      })
      .finally(() => setLoading(false));
  };

  const exit = () => (isDirty ? setExitModal(true) : navigate('/retranslations'));

  return (
    <div className={styles['wrapper']}>
      {loading && <Loader />}
      {!loading && (
        <>
          <header className={styles['header']}>
            <Button className={styles['back']} variant={ButtonTypes.SR} onClick={exit}>
              <Back />
            </Button>
            {isCreate ? 'Создание' : 'Редактирование'} ретрансляции
          </header>
          <div className={styles['border']} />
          <div className={styles['form']}>
            <Input
              className={styles['name']}
              label="Название"
              register={register('name', { required: ERRORS.REQUIRED('Название') })}
              disabled={loading}
              error={errors.name}
            />
            <TextArea
              className={styles['description']}
              label="Описание"
              rows={2}
              register={register('description')}
              disabled={loading}
            />
            <Controller
              control={control}
              name="protocol"
              rules={{ required: ERRORS.REQUIRED('Протокол') }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Select
                  className={styles['protocol']}
                  label="Протокол"
                  list={PROTOCOLS.map(id => ({ id, title: id }))}
                  selected={value}
                  onSelect={onChange}
                  onBlur={onBlur}
                  error={error}
                  disabled={loading}
                />
              )}
            />
            <Input
              className={styles['server']}
              label="Адрес сервера"
              register={register('server', { required: ERRORS.REQUIRED('Адрес сервера') })}
              disabled={loading}
              error={errors.server}
            />
            <Input
              className={styles['port']}
              label="Порт"
              register={register('port', { required: ERRORS.REQUIRED('Порт') })}
              disabled={loading}
              error={errors.port}
            />
            <Input
              className={styles['credentials']}
              label="Логин:Пароль"
              register={register('credentials')}
              disabled={loading}
            />
          </div>
          {!isCreate && (
            <>
              <div className={styles['border']} />
              <RetranslationVehicles disabled={loading} retranslationId={retranslationId} />
            </>
          )}
          <Button className={styles['submit']} disabled={!isValid || loading} onClick={() => handleSubmit(submit)()}>
            {isCreate ? 'Создать' : 'Редактировать'}
          </Button>

          <AlertModal
            open={exitModal}
            setOpen={() => setExitModal(!exitModal)}
            action={() => navigate('/retranslations')}
          />
        </>
      )}
    </div>
  );
};
