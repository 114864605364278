import { trimObject } from '@bs/techconnect-ui';
import { createBaseApi, configRequest, prepareQuery } from './baseAPI';

const baseApi = createBaseApi();

export const deviceService = { getDevices, getDevice, createDevice, updateDevice, deleteDevice, provisioning };

function getDevices(params = {}) {
  return baseApi.get(`/devices?${prepareQuery(params)}`, configRequest);
}

function getDevice(deviceId) {
  return baseApi.get(`/devices/${deviceId}`, configRequest);
}

function createDevice(body) {
  return baseApi.post('/devices', trimObject(body), configRequest);
}

function updateDevice(body, id) {
  return baseApi.put(`/devices/${id}`, trimObject(body), configRequest);
}

function deleteDevice(id) {
  return baseApi.delete(`/devices/${id}`, configRequest);
}

function provisioning(body) {
  return baseApi.post('/devices/environment', trimObject(body), configRequest);
}
