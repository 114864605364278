import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { USER_COLUMNS } from 'components/AccessGroups/helpers';
import { accessGroupService, userService } from 'services';
import { Table, TableTypes, Input, Button, ButtonTypes, parseFIO } from '@bs/techconnect-ui';
import { ReactComponent as Search } from 'icons/search.svg';
import { ReactComponent as Clear } from 'icons/cross.svg';
import { ReactComponent as Add } from 'icons/plus-s.svg';
import { ReactComponent as Remove } from 'icons/minus.svg';

import styles from '../AccessGroupsCreate.module.css';

export const UserList = ({ open, disabled, accessGroup, usersAGCount, setUsersAGCount }) => {
  const [search, setSearch] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const [loading, setLoading] = useState(false);

  //ALL USERS
  const [users, setUsers] = useState([]);
  const [usersPage, setUsersPage] = useState(1);
  const [usersTotalPages, setUsersTotalPages] = useState(1);
  const [usersCount, setUsersCount] = useState(0);
  //AG USERS
  const [usersAG, setUsersAG] = useState([]);
  const [usersAGPage, setUsersAGPage] = useState(1);
  const [usersAGTotalPages, setUsersAGTotalPages] = useState(1);

  const fetchUsers = useCallback(() => {
    userService.getUsers({ page: usersPage, pageSize: 8, search }).then(({ status, data }) => {
      if (status === 200 && Array.isArray(data?.items)) setUsers(data.items);
      if (!isNaN(data.totalPages)) setUsersTotalPages(data.totalPages);
      if (!isNaN(data.count)) setUsersCount(data.count);
    });
  }, [search, usersPage]);

  const fetchUsersAG = useCallback(() => {
    accessGroupService
      .getAccessGroupUsers(accessGroup, { page: usersAGPage, pageSize: 8, search })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) setUsersAG(data.items.map(v => ({ ...v, ag: true })));
        if (!isNaN(data.totalPages)) setUsersAGTotalPages(data.totalPages);
        if (!isNaN(data.count)) setUsersAGCount(data.count);
      });
  }, [accessGroup, search, setUsersAGCount, usersAGPage]);

  useEffect(() => {
    if (accessGroup) fetchUsers();
  }, [accessGroup, fetchUsers, usersPage]);

  useEffect(() => {
    if (accessGroup) fetchUsersAG();
  }, [accessGroup, usersAGPage, fetchUsersAG]);

  const apply = () => {
    setUsersPage(1);
    setSearch(searchInput);
  };

  const clear = () => {
    setSearchInput('');
    setSearch('');
  };

  const handleUser = ({ id, ag }) => {
    setLoading(true);
    const method = ag ? accessGroupService.deleteUsersFromAccessGroup : accessGroupService.postUsersToAccessGroup;
    method(accessGroup, { users: [id] })
      .then(({ status }) => {
        if (status === 204) fetchUsersAG();
      })
      .finally(() => setLoading(false));
  };

  const handler = row => ({
    ...row,
    action: (
      <Button variant={ButtonTypes.T} onClick={() => handleUser(row)} disabled={loading || disabled}>
        {row.ag ? <Remove /> : <Add />}
      </Button>
    ),
    fio: parseFIO(row),
  });

  if (!open) return null;

  return (
    <>
      <div className={styles['filters']}>
        <Input
          placeholder="ФИО, телефон или E-mail"
          value={searchInput}
          onChange={({ target }) => setSearchInput(target.value)}
          disabled={disabled || loading}
          onEnter={apply}
        />
        <Button disabled={disabled || loading} variant={ButtonTypes.S} onClick={apply}>
          <Search />
        </Button>

        <Button variant={ButtonTypes.T} onClick={clear} disabled={!search || disabled || loading}>
          Сбросить фильтр&nbsp;
          <Clear />
        </Button>
      </div>

      <div className={styles['list']}>
        <div className={styles['list-column']}>
          <header>Все пользователи ({usersCount})</header>
          <Table
            variant={TableTypes.S}
            columns={USER_COLUMNS}
            loading={loading}
            rows={users}
            totalPages={usersTotalPages}
            setPage={setUsersPage}
            page={usersPage}
            idKey="id"
            handler={handler}
          />
        </div>

        <div className={styles['list-column']}>
          <header>В группе ({usersAGCount})</header>
          <Table
            variant={TableTypes.S}
            columns={USER_COLUMNS}
            loading={loading}
            rows={usersAG}
            totalPages={usersAGTotalPages}
            setPage={setUsersAGPage}
            page={usersAGPage}
            idKey="id"
            handler={handler}
          />
        </div>
      </div>
    </>
  );
};

UserList.defaultProps = {
  open: false,
  disabled: false,
  accessGroup: '',
  usersAGCount: 0,
  setUsersAGCount: () => {},
};

UserList.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  accessGroup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  usersAGCount: PropTypes.number,
  setUsersAGCount: PropTypes.func,
};
