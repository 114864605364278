import { parseDate } from '@bs/techconnect-ui';
import { EMPTY } from 'helpers';

export const FILTERS = [
  { key: 'vehicleId', title: 'VehicleId' },
  { key: 'vin', title: 'VIN' },
  { key: 'plateNumber', title: 'Гос. номер' },
  { key: 'esn', title: 'ESN' },
  { key: 'iccid', title: 'ICCID' },
];
export const COLUMNS = [
  { key: 'id', title: 'VehicleID' },
  { key: 'vin', title: 'VIN' },
  { key: 'plateNumber', title: 'Гос. номер' },
  { key: 'plant', title: 'Автопроизводитель' },
  { key: 'brand', title: 'Бренд' },
  { key: 'model', title: 'Модель' },
  { key: 'deviceId', title: 'DeviceId' },
  { key: 'esn', title: 'ESN' },
  { key: 'owner', title: 'E-mail владельца' },
  { key: 'commands', title: '' },
  { key: 'telemetry', title: '' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const DEVICE_FILTERS = {
  deviceId: 'DeviceId',
  esn: 'ESN',
  vehicleId: 'VehicleId',
};
export const DEVICE_COLUMNS = [
  { key: 'action', title: '' },
  { key: 'id', title: 'DeviceId' },
  { key: 'esn', title: 'ESN' },
  { key: 'vehicleId', title: 'VehicleID' },
  { key: 'status', title: 'Status' },
];

export const COMMAND_FIELDS = [
  {
    id: 'engine',
    key: s => (engineOff(s) ? 'engineStart' : 'engineStop'),
    title: s => (engineOff(s) ? 'Запуск двигателя' : 'Выключение двигателя'),
    validation: o => o?.hasRemoteEngineStart,
  },
  {
    id: 'panic',
    key: () => 'panic',
    title: () => 'Сигнал',
    validation: o => o?.hasPanicMode,
  },
  {
    id: 'tailgate',
    key: s => (!s?.tailgate ? 'tailgateOpen' : 'tailgateClose'),
    title: s => (!s?.tailgate ? 'Открытие багажника' : 'Закрытие багажника'),
    validation: o => o?.hasTailgateOpenControl && o?.hasTailgateData,
  },
  {
    id: 'lock',
    key: s => (s?.vehicleLockType !== 6 ? 'unlock' : 'lock'),
    title: s => (s?.vehicleLockType !== 6 ? 'Открытие замка' : 'Закрытие замка'),
    validation: o => o?.hasCentralLockingControl && o?.hasCentralLockingData,
  },
  {
    id: 'auth',
    key: s => (s?.tcuAuthState ? 'unauthorize' : 'authorize'),
    title: s => (s?.tcuAuthState ? 'Деавторизация TCU' : 'Авторизация TCU'),
  },
  {
    id: 'calibration',
    key: () => 'accelCalibration',
    title: () => 'Калибровка акселерометра',
  },
  {
    id: 'initialConfig',
    key: () => 'initialConfig',
    title: () => 'Исходная конфигурация',
  },
  {
    id: 'getMetaData',
    key: () => 'getMetaData',
    title: () => 'Получение метаданных',
  },
];
const engineOff = s => s?.powerPackStatus === 0 || s?.powerPackStatus === 1;
export const COMMANDS_STATUSES = {
  inProgress: 'inProgress',
  completed: 'completed',
  failed: 'failed',
};

export const TELEMETRY_COLUMN_1 = {
  createdAt: v => parseDate(v) || EMPTY,
  receivedAt: v => parseDate(v) || EMPTY,
  tcuAuthState: v => v?.toString() || EMPTY,
  tcuConfigState: v => v?.toString() || EMPTY,
  tcuCalibrationState: v => v?.toString() || EMPTY,
  VIN: (v, data) => data?.vin || EMPTY,
  position: (v, data) => [data.lat || EMPTY, data.lon || EMPTY].join(' ; '),
  speed: v => (isNaN(v) ? EMPTY : v),
  fuelLevel: v => (isNaN(v) ? EMPTY : v),
};

export const TELEMETRY_COLUMN_2 = {
  odometer: v => (isNaN(v) ? EMPTY : v),
  batteryVoltage: v => (isNaN(v) ? EMPTY : v.toFixed(1)),
  engineCoolantTemperature: v => (isNaN(v) ? EMPTY : v),
  engineLoad: (v, data) => (isNaN(data?.engineLoad) ? EMPTY : data?.engineLoad),
  satQty: v => (isNaN(v) ? EMPTY : v),
  gsmCellBasesQty: v => (isNaN(v) ? EMPTY : v),
  signalStrength: v => (isNaN(v) ? EMPTY : v),
};

export const processVehicle = v => {
  return {
    ...v,
    info: {
      esn: v?.device?.esn || EMPTY,
      lastActivity: parseDate(v?.state?.lastActivity) || EMPTY,
      status: v?.device?.status || EMPTY,
      email: v?.owner?.email || EMPTY,
    },
  };
};

export const INFO_FIELDS = {
  esn: 'ESN',
  lastActivity: 'Последняя активность',
  status: 'Статус Provisioning',
  email: 'E-mail владельца',
};

export const dayInSec = 24 * 60 * 60 * 1000;
