import { trimObject } from '@bs/techconnect-ui';

const { createBaseApi, configRequest, prepareQuery } = require('./baseAPI');

const baseApi = createBaseApi();

export const plantsService = {
  getPlants,
  deletePlants,
  getPlantsById,
  createPlants,
  updatePlants,
};

function getPlants(params = {}) {
  return baseApi.get(`/plants?${prepareQuery(params)}`, configRequest);
}

function getPlantsById(id) {
  return baseApi.get(`/plants/${id}`, configRequest);
}

function createPlants(body) {
  return baseApi.post('/plants', trimObject(body), configRequest);
}

function updatePlants(body, id) {
  return baseApi.put(`/plants/${id}`, trimObject(body), configRequest);
}

function deletePlants(id) {
  return baseApi.delete(`/plants/${id}`, configRequest);
}
