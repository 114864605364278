export const ROLES_ENUM = {
  admin: 'admins',
  dealer: 'dealer',
  mobile: 'mobile',
  hardware: 'hardware',
  oem: 'oem',
  marketing: 'marketing',
  IoTAdministrator: 'IoTAdministrator',
};

export const ROLES = {
  [ROLES_ENUM.admin]: 'Administrator',
  [ROLES_ENUM.dealer]: 'Dealer',
  [ROLES_ENUM.mobile]: 'Mobile',
  [ROLES_ENUM.hardware]: 'HardwareEngineer',
  [ROLES_ENUM.oem]: 'OEM',
  [ROLES_ENUM.marketing]: 'Marketing',
  [ROLES_ENUM.IoTAdministrator]: 'IoTAdministrator',
};

export const NAV_MENU = [
  { key: '/vehicles', title: 'Транспортные средства', ability: [ROLES_ENUM.admin] },
  { key: '/devices', title: 'Устройства', ability: [ROLES_ENUM.admin] },
  { key: '/options', title: 'Опции', ability: [ROLES_ENUM.admin] },
  { key: '/brandsModels', title: 'Бренды и модели', ability: [ROLES_ENUM.admin] },
  { key: '/accessGroups', title: 'Группы доступа', ability: [ROLES_ENUM.admin] },
  { key: '/users', title: 'Пользователи', ability: [ROLES_ENUM.admin] },
  // { key: '/plants', title: 'Автопроизводители' },
  { key: '/iotDevices', title: 'Устройства в IoT платформе', ability: [ROLES_ENUM.IoTAdministrator] },
  { key: '/retranslations', title: 'Ретрансляции', ability: [ROLES_ENUM.admin] },
];

export const EMPTY = '-';

export const oidcConfig = {
  authority: process.env.REACT_APP_AUTH_URL,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
  response_type: 'code',
  scope: process.env.REACT_APP_AUTH_SCOPE,
  automaticSilentRenew: true,
  loadUserInfo: true,
  monitorSession: false,
  post_logout_redirect_uri: process.env.REACT_APP_SIGNOUT_REDIRECT_URL,
};

export const OPTIONS_LIST_ENUM = {
  BOOLEAN: 'Boolean',
  FLOAT: 'Float',
  STRING: 'String',
  INTEGER: 'Integer',
  LIST: 'List',
};
