import { trimObject } from '@bs/techconnect-ui';
import { createBaseApi, configRequest, prepareQuery } from './baseAPI';

const baseApi = createBaseApi();

export const retranslationsService = {
  getRetranslations,
  startRetranslation,
  stopRetranslation,
  getRetranslation,
  createRetranslation,
  updateRetranslation,
  deleteRetranslation,
  getRetranslationVehicles,
  postVehiclesToRetranslation,
  deleteVehiclesFromRetranslation,
};

function getRetranslations(params = {}) {
  return baseApi.get(`/retranslations?${prepareQuery(params)}`, configRequest);
}

function startRetranslation(retranslationId) {
  return baseApi.post(`/retranslations/${retranslationId}/start`, configRequest);
}

function stopRetranslation(retranslationId) {
  return baseApi.post(`/retranslations/${retranslationId}/stop`, configRequest);
}

function getRetranslation(retranslationId) {
  return baseApi.get(`/retranslations/${retranslationId}`, configRequest);
}

function createRetranslation(body) {
  return baseApi.post('/retranslations', trimObject(body), configRequest);
}

function updateRetranslation(body, retranslationId) {
  return baseApi.put(`/retranslations/${retranslationId}`, trimObject(body), configRequest);
}

function deleteRetranslation(retranslationId) {
  return baseApi.delete(`/retranslations/${retranslationId}`, configRequest);
}

function getRetranslationVehicles(retranslationId, params = {}) {
  return baseApi.get(`/retranslations/${retranslationId}/devices?${prepareQuery(params)}`, configRequest);
}
function postVehiclesToRetranslation(retranslationId, body) {
  return baseApi.post(`/retranslations/${retranslationId}/devices`, trimObject(body), configRequest);
}
function deleteVehiclesFromRetranslation(retranslationId, data) {
  return baseApi.delete(`/retranslations/${retranslationId}/devices`, { data }, configRequest);
}
