import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { plantsService, userService, dealerService } from 'services';
import { USER_CREATE_ROLES_LIST, USER_FORM } from '../helpers';
import { ROLES_ENUM, useSuccess } from 'helpers';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { Modal, Select, Input, Button, ButtonTypes, ERRORS, updateFormErrors } from '@bs/techconnect-ui';
import { ReactComponent as Close } from 'icons/cross.svg';

import styles from './UsersCreate.module.css';

export const UsersCreate = ({ open, setOpen, user, updateList }) => {
  const { setSuccess } = useSuccess();

  const [loading, setLoading] = useState(false);
  const [plants, setPlants] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [exitModal, setExitModal] = useState(false);

  const {
    control,
    reset,
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setError,
    watch,
  } = useForm({ mode: 'onTouched' });

  const role = watch('role');
  const isOEM = role === ROLES_ENUM.oem;
  const isDealer = role === ROLES_ENUM.dealer;

  const fetchUser = useCallback(() => {
    setLoading(true);
    userService
      .getUser(user.id)
      .then(({ status, data }) => {
        if (status === 200) {
          const { name, surname, phone, roles, email, plant, dealer } = data || {};
          reset({ name, surname, phone, role: roles, email, plantId: plant?.id, dealerId: dealer?.id });
        }
      })
      .finally(() => setLoading(false));
  }, [reset, user.id]);

  useEffect(() => {
    if (user.id) fetchUser();
  }, [fetchUser, user]);

  useEffect(() => {
    if (isOEM) {
      setLoading(true);
      plantsService
        .getPlants()
        .then(({ data, status }) => {
          if (status === 200 && Array.isArray(data?.items)) {
            setPlants(data.items.map(plant => ({ id: plant.id, title: plant.name })));
          }
        })
        .finally(() => setLoading(false));
    }
  }, [isOEM]);

  useEffect(() => {
    if (isDealer) {
      setLoading(true);
      dealerService
        .getDealers()
        .then(({ data, status }) => {
          if (status === 200 && Array.isArray(data?.items)) {
            setDealers(data.items.map(dealer => ({ id: dealer.id, title: dealer.name })));
          }
        })
        .finally(() => setLoading(false));
    }
  }, [isDealer]);

  const create = form => {
    const method = user.id ? userService.updateUser : userService.createUser;
    method(form, user.id).then(({ data, status }) => {
      if (status !== 200) updateFormErrors(data, form, setError);
      if (status === 200) {
        setSuccess(`${user.id ? 'Изменения сохранены' : 'Пользователь создан'} `);
        updateList();
        close();
      }
    });
  };
  const close = () => {
    reset({});
    setOpen();
    setExitModal(false);
  };

  return (
    <Modal open={open} className={styles['wrapper']}>
      <header className={styles['header']}>
        <h3>{user.id ? 'Редактирование' : 'Создание'} пользователя</h3>
      </header>
      {!!user.id && (
        <div className={styles['user-id']}>
          <span>UserId: </span>
          <span>{user.id}</span>
        </div>
      )}
      <Button variant={ButtonTypes.T} className={styles['close']} onClick={isDirty ? () => setExitModal(true) : close}>
        <Close />
      </Button>

      {USER_FORM.map(({ key, title, options }, index) => (
        <Input
          key={key}
          placeholder={title}
          className={styles['params-field']}
          register={register(key, options)}
          error={errors[key]}
          disabled={loading || (key === 'email' && user?.id)}
          autoFocus={!index}
        />
      ))}

      <Controller
        control={control}
        name="role"
        rules={{ required: ERRORS.REQUIRED('Роль') }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <Select
            className={styles['params-field']}
            placeholder="Роль"
            list={USER_CREATE_ROLES_LIST.map(r => ({ ...r, id: r.key }))}
            selected={value}
            onSelect={onChange}
            onBlur={onBlur}
            error={error}
            disabled={loading || user?.id}
          />
        )}
      />
      {isOEM && (
        <Controller
          control={control}
          name="plantId"
          rules={{ required: ERRORS.REQUIRED('Автопроизводитель') }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <Select
              className={styles['params-field']}
              placeholder="Автопроизводители"
              list={plants}
              selected={value}
              onSelect={onChange}
              onBlur={onBlur}
              error={error}
              disabled={loading || user?.id}
            />
          )}
        />
      )}
      {isDealer && (
        <Controller
          control={control}
          name="dealerId"
          rules={{ required: ERRORS.REQUIRED('Дилер') }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <Select
              className={styles['params-field']}
              placeholder="Дилерские центры"
              list={dealers}
              selected={value}
              onSelect={onChange}
              onBlur={onBlur}
              error={error}
              disabled={loading || user?.id}
            />
          )}
        />
      )}

      <footer className={styles['footer']}>
        <Button loading={loading} disabled={!isValid || loading} onClick={() => handleSubmit(create)()}>
          {user.id ? 'Редактировать' : 'Создать'}
        </Button>
      </footer>

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={close} />
    </Modal>
  );
};

UsersCreate.defaultProps = {
  open: false,
  setOpen: () => {},
  user: {},
  updateList: () => {},
};

UsersCreate.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  updateList: PropTypes.func,
};
