import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import { deviceService } from 'services';
import { DEVICE_FORM } from '../helpers';
import { useSuccess } from 'helpers';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { Modal, Checkbox, Input, Button, ButtonTypes, updateFormErrors } from '@bs/techconnect-ui';
import { ReactComponent as Close } from 'icons/cross.svg';

import styles from '../Devices.module.css';

export const DeviceForm = ({ deviceId, open, setOpen, updateList }) => {
  const { setSuccess } = useSuccess();

  const [device, setDevice] = useState({});
  const [loading, setLoading] = useState(false);
  const [exitModal, setExitModal] = useState(false);

  const {
    reset,
    register,
    formState: { errors, isSubmitting, isValid, isDirty },
    handleSubmit,
    setError,
    control,
  } = useForm({ mode: 'onTouched' });

  const fetchDevice = useCallback(() => {
    setLoading(true);
    deviceService
      .getDevice(deviceId)
      .then(({ data, status }) => {
        if (status === 200 && data) setDevice(data);
      })
      .finally(() => setLoading(false));
  }, [deviceId]);
  useEffect(() => {
    if (deviceId) fetchDevice();
  }, [fetchDevice, deviceId]);

  useEffect(() => {
    reset(device);
  }, [device, reset]);

  const close = () => {
    reset({});
    setOpen();
    setExitModal(false);
  };

  const create = form => {
    const { deviceId, isTesting } = form;
    const body = { isTesting: !!isTesting };
    DEVICE_FORM.forEach(({ key }) => (body[key] = form[key]));
    const method = deviceId ? deviceService.updateDevice : deviceService.createDevice;
    setLoading(true);
    method(body, deviceId)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          setSuccess(`${deviceId ? 'Изменения сохранены' : 'Устройство создано'} `);
          updateList();
          close();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal open={open} className={styles['create-wrapper']}>
      <header>{device?.deviceId ? 'Редактирование' : 'Создание'} устройства</header>

      <Button
        variant={ButtonTypes.T}
        className={styles['create-close']}
        onClick={isDirty ? () => setExitModal(true) : close}
      >
        <Close />
      </Button>

      <div className={styles['form']}>
        <Controller
          control={control}
          name="isTesting"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              className={styles['create-field']}
              label="Тестовое устройство"
              checked={value}
              onChange={() => onChange(!value)}
              disabled={loading}
            />
          )}
        />
        {DEVICE_FORM.map(({ key, title, options }) => (
          <Input
            key={key}
            placeholder={title}
            className={styles['create-field']}
            register={register(key, options)}
            error={errors[key]}
            disabled={loading}
          />
        ))}
      </div>

      <footer>
        <Button onClick={() => handleSubmit(create)()} loading={loading} disabled={isSubmitting || !isValid}>
          {device?.deviceId ? 'Редактировать' : 'Создать'}
        </Button>
      </footer>

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={close} />
    </Modal>
  );
};

DeviceForm.defaultProps = {
  deviceId: '',
  open: false,
  setOpen: () => {},
  updateList: () => {},
};

DeviceForm.propTypes = {
  deviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updateList: PropTypes.func,
};
