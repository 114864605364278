import { trimObject } from '@bs/techconnect-ui';
import { createBaseApi, configRequest } from './baseAPI';

const baseApi = createBaseApi();

export const optionValuesService = {
  updateOptionValue,
  deleteOptionValue,
};

function updateOptionValue(body, id) {
  return baseApi.put(`/optionvalues/${id}`, trimObject(body), configRequest);
}
function deleteOptionValue(id) {
  return baseApi.delete(`/optionvalues/${id}`, configRequest);
}
