import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { telemetryService, vehicleCommand, vehicleService } from 'services';
import { useError } from 'helpers';
import { COMMANDS_STATUSES, COMMAND_FIELDS } from '../helpers';
import { Loader, Modal, Button, ButtonTypes, useTelemetry } from '@bs/techconnect-ui';
import { ReactComponent as Close } from 'icons/cross.svg';

import styles from './VehicleCommands.module.css';

export const VehicleCommands = ({ vehicle, setOpen }) => {
  const { vehicleId, device } = vehicle;
  const { deviceId } = device || {};
  const { setError } = useError();

  const [loading, setLoading] = useState(false);
  // const [channel, setChannel] = useState('');

  //ПОЛУЧЕНИЕ НАЧАЛЬНОГО СТЕЙТА И ОПЦИЙ
  const fetchVehicle = useCallback(() => {
    setLoading(true);
    vehicleService
      .getVehicle(vehicleId)
      .then(({ data, status }) => {
        if (status === 200 && data) {
          if (data?.state) setVehicleState(data.state);
          if (data.optionValues) {
            setAvailableOption(() => {
              const listOption = {};
              data.optionValues.forEach(option => {
                listOption[option.option?.key] = option?.value;
              });
              return listOption;
            });
          }
        }
      })
      .finally(() => setLoading(false));
  }, [vehicleId]);
  useEffect(() => {
    if (vehicleId) fetchVehicle();
  }, [fetchVehicle, vehicleId]);
  //ПОДПИСКА НА ТЕЛЕМЕТРИЮ
  const { stateData } = useTelemetry(deviceId, vehicleId, telemetryService.subscribe, telemetryService.unsubscribe);
  useEffect(() => {
    setVehicleState(prev => {
      const { tcuAuthState, tcuCalibrationState, vehicleLockType, powerPackStatus } = stateData;
      return { ...prev, tcuAuthState, tcuCalibrationState, vehicleLockType, powerPackStatus };
    });
  }, [deviceId, vehicleId, stateData]);

  const [vehicleState, setVehicleState] = useState({});
  const [availableOption, setAvailableOption] = useState({});
  const [commandsStatus, setCommandsStatus] = useState({});
  const checkCommand = useCallback(
    ({ id, key }) => {
      vehicleCommand.getCommandStatus(id).then(({ data: dataStatus, status }) => {
        if (status === 200) {
          const { state, id, failReason } = dataStatus;
          if (state === COMMANDS_STATUSES.failed) setError(failReason);
          setCommandsStatus(prev => ({ ...prev, [key]: { key, state, id } }));
        }
      });
    },
    [setError],
  );
  const sendCommand = (action, key) =>
    vehicleCommand[action]({ vehicleId }).then(
      ({ data, status }) => status === 200 && checkCommand({ id: data.id, key }),
    );
  useEffect(() => {
    const interval = setInterval(() => {
      const inProgress = [];
      Object.keys(commandsStatus).map(
        c => commandsStatus[c].state === COMMANDS_STATUSES.inProgress && inProgress.push(commandsStatus[c]),
      );
      inProgress.forEach(checkCommand);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [checkCommand, commandsStatus]);

  const close = () => {
    setOpen();
  };

  const renderCommand = ({ id, validation, key, title }) => (
    <Button
      key={id}
      variant={ButtonTypes.SR}
      className={styles[commandsStatus[id]?.state]}
      disabled={
        (validation && !validation(availableOption)) || commandsStatus[id]?.state === COMMANDS_STATUSES.inProgress
      }
      onClick={() => sendCommand(key(vehicleState), id)}
    >
      {title(vehicleState)}
    </Button>
  );

  return (
    <Modal open={!!vehicleId} className={styles['wrapper']}>
      <Button variant={ButtonTypes.T} className={styles['close']} onClick={close}>
        <Close />
      </Button>
      <header>Команды</header>
      {loading && <Loader />}
      {!loading && (
        <div className={styles['body']}>
          <div className={styles['column']}>{COMMAND_FIELDS.slice(0, 4).map(renderCommand)}</div>
          <div className={styles['column']}>{COMMAND_FIELDS.slice(4).map(renderCommand)}</div>
        </div>
      )}
    </Modal>
  );
};

VehicleCommands.defaultProps = {
  vehicle: {},
  setOpen: () => {},
};

VehicleCommands.propTypes = {
  vehicle: PropTypes.object,
  setOpen: PropTypes.func,
};
