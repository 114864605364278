import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { accessGroupService } from 'services';
import { COLUMNS, FILTERS } from './helpers';

import { Popper, Table, Button, ButtonTypes, ListFilters, pageSize } from '@bs/techconnect-ui';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { AccessGroupsCreate } from './AccessGroupsCreate/AccessGroupsCreate';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import styles from './AccessGroups.module.css';

const AccessGroups = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const fetchAccessGroups = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem(`accessGroupsFilters`) || '{}') || {};
    const { filter = false, ...queryFilter } = filterData;
    setLoading(true);
    accessGroupService
      .getAccessGroups({ page, pageSize, ...(filter && queryFilter) })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(data.items);
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [page, filters]);

  useEffect(() => {
    fetchAccessGroups();
  }, [page, filters, fetchAccessGroups]);

  const handler = row => ({
    ...row,

    edit: (
      <Popper popup={'Редактировать'}>
        <Button variant={ButtonTypes.TL} onClick={() => setEditModal(row)}>
          <Edit />
        </Button>
      </Popper>
    ),
    delete: (
      <Popper popup={'Удалить'}>
        <Button variant={ButtonTypes.TL} onClick={() => setDeleteModal(row.id)}>
          <Delete />
        </Button>
      </Popper>
    ),
  });

  return (
    <>
      <ListFilters
        title="Группы доступа"
        fields={FILTERS}
        filters={filters}
        setFilters={setFilters}
        CreateModal={AccessGroupsCreate}
        updateList={fetchAccessGroups}
      />

      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        loading={loading}
        idKey="id"
        handler={handler}
      />

      <AccessGroupsCreate
        accessGroup={editModal}
        open={!!editModal}
        setOpen={() => setEditModal(!editModal)}
        updateList={fetchAccessGroups}
      />

      <ConfirmModal
        open={deleteModal}
        setOpen={() => setDeleteModal(!deleteModal)}
        updateList={fetchAccessGroups}
        method={() => accessGroupService.deleteAccessGroup(deleteModal)}
        header="Удалить группу доступа?"
        description="Я понимаю, что восстановить группу доступа будет невозможно"
      />
    </>
  );
};

export default AccessGroups;
