import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

import { StateProvider } from 'helpers';
import MainLayout from './MainLayout/MainLayout';
import { Login } from 'components/Login/Login';
import { Redirect } from 'components/Login/Redirect/Redirect';
import { Logout } from 'components/Login/Logout/Logout';
import { PrivateWrapper } from '@bs/techconnect-ui';

const App = () => {
  const { events, signinSilent, isLoading, isAuthenticated } = useAuth();

  //SILENT REFRESH TOKEN
  useEffect(() => {
    return events.addAccessTokenExpiring(signinSilent);
  }, [events, signinSilent]);

  return (
    <StateProvider>
      <Routes>
        <Route element={<PrivateWrapper isLoading={isLoading} isAuthenticated={isAuthenticated} />}>
          <Route path="*" element={<MainLayout />} />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/redirect" element={<Redirect />} />
        <Route path="/signout" element={<Logout />} />
      </Routes>
    </StateProvider>
  );
};

export default App;
