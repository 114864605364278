import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { plantsService } from 'services';
import { COLUMNS } from './helpers';

import { Paper, Table, Button, ButtonTypes, pageSize } from '@bs/techconnect-ui';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as Add } from 'icons/plus.svg';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { PlantsForm } from './PlantsForm';

import styles from './Plants.module.css';

const Plants = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const fetchPlants = useCallback(() => {
    setLoading(true);
    plantsService
      .getPlants({ page, pageSize })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(data.items);
        }
        if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    fetchPlants();
  }, [fetchPlants]);

  const handler = row => ({
    ...row,
    edit: (
      <Button
        variant={ButtonTypes.TL}
        onClick={() => {
          setEditModal(row);
        }}
      >
        <Edit />
      </Button>
    ),
    delete: (
      <Button variant={ButtonTypes.TL} onClick={() => setDeleteModal(row.id)}>
        <Delete />
      </Button>
    ),
  });
  return (
    <>
      <Paper className={styles['filters-wrapper']}>
        <header>
          <span>Автопроизводители</span>
          <Button variant={ButtonTypes.S} onClick={() => setEditModal(!editModal)}>
            <Add />
          </Button>
        </header>
      </Paper>
      <div className={styles['body']}>
        <Table
          className={styles['table']}
          columns={COLUMNS}
          rows={rows}
          totalPages={totalPages}
          idKey="id"
          handler={handler}
          loading={loading}
        />
        <PlantsForm
          plant={editModal}
          open={!!editModal}
          setOpen={() => setEditModal(!editModal)}
          updateList={fetchPlants}
        />
      </div>

      <ConfirmModal
        open={deleteModal}
        updateList={fetchPlants}
        setOpen={() => setDeleteModal(!deleteModal)}
        method={() => plantsService.deletePlants(deleteModal)}
        header="Удалить автопроизводителя?"
        description="Я понимаю, что восстановить автопроизводителя будет невозможно"
      />
    </>
  );
};

export default Plants;
