import { ERRORS } from '@bs/techconnect-ui';

export const FILTERS = [
  { key: 'deviceId', title: 'DeviceId' },
  { key: 'esn', title: 'ESN' },
  { key: 'vehicleId', title: 'VehicleId' },
];

export const COLUMNS = [
  { key: 'id', title: 'DeviceId' },
  { key: 'esn', title: 'ESN' },
  { key: 'vehicleId', title: 'VehicleId' },
  { key: 'lastActivity', title: 'Последняя активность' },
  { key: 'status', title: 'Status' },
  { key: 'telemetry', title: '' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const DEVICE_FORM = [
  { key: 'calibrationNumber', title: 'Calibration Number', options: {} },
  { key: 'imei', title: 'IMEI', options: { required: ERRORS.REQUIRED('IMEI') } },
  { key: 'strategyNumber', title: 'Strategy Number', options: {} },
  { key: 'iccid', title: 'ICCID', options: { required: ERRORS.REQUIRED('ICCID') } },
  { key: 'bootloaderNumber', title: 'Bootloader Number', options: {} },
  { key: 'msisdn', title: 'MSISDN', options: {} },
  { key: 'hardwareNumber', title: 'Hardware Number', options: {} },
  { key: 'esn', title: 'ESN', options: { required: ERRORS.REQUIRED('ESN') } },
  { key: 'moduleNumber', title: 'Module Number', options: {} },
];
