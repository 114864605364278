const { createBaseApi, configRequest, prepareQuery } = require('./baseAPI');

const baseApi = createBaseApi();

export const dealerService = {
  getDealers,
  deleteDealer,
  getDealerById,
  getModels,
  createDealer,
  updateDealer,
};

function getDealers(params = {}) {
  return baseApi.get(`/dealers?${prepareQuery(params)}`, configRequest);
}

function getDealerById(id) {
  return baseApi.get(`/dealers/${id}`, configRequest);
}
function createDealer(body) {
  return baseApi.post('/dealers', body, configRequest);
}

function updateDealer(body, id) {
  return baseApi.put(`/dealers/${id}`, body, configRequest);
}

function deleteDealer(id) {
  return baseApi.delete(`/dealers/${id}`, configRequest);
}

function getModels() {
  return baseApi.get('/models', configRequest);
}
