import { trimObject } from '@bs/techconnect-ui';
import { createBaseApi, configRequest } from './baseAPI';

const baseApi = createBaseApi();

export const iotDevicesService = { getIotDevices };

function getIotDevices(body) {
  return baseApi.post('/iot/generic', trimObject(body), configRequest);
}
