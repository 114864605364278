import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';

import { oidcConfig } from 'helpers';
import App from 'components/App/App';
import { Loader } from '@bs/techconnect-ui';

import './polyfill';
import '../node_modules/leaflet/dist/leaflet.css';
import '../node_modules/@bs/techconnect-ui/dist/styles/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<Loader />}>
    <AuthProvider {...oidcConfig}>
      <BrowserRouter>
        <App />
        <div className="copyright">Ⓒ Technolabs</div>
      </BrowserRouter>
    </AuthProvider>
  </Suspense>,
);
