import axios from 'axios';

export function createBaseApi(apiVersion = 'v1', timeout = 0) {
  const baseApi = axios.create({ baseURL: `/api/${apiVersion}` });

  // Обработка ОТВЕТА
  baseApi.interceptors.response.use(
    response => {
      return response;
    },
    (error = {}) => {
      const { response } = error;
      if (response?.status === 401) window.location.pathname = '/signout';
      sessionStorage.setItem('error', JSON.stringify(response));
      //На других вкладках это будет вызывать два вызова события "storage"
      //Проблемой быть не должно
      window.dispatchEvent(new Event('storage'));

      return response;
    },
  );

  // Обработка ЗАПРОСА
  baseApi.interceptors.request.use(
    config => {
      const { REACT_APP_AUTH_URL: authUrl, REACT_APP_IDENTITY_CLIENT_ID: clientId } = process.env;
      const { access_token } = JSON.parse(sessionStorage.getItem(`oidc.user:${authUrl}:${clientId}`)) || {};
      config.headers = {
        ...config.headers,
        Authorization: ['Bearer', access_token].join(' '),
      };
      config.timeout = timeout;
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  return baseApi;
}

export const configRequest = createConfig();

export const prepareQuery = params => {
  const query = [];
  Object.keys(params).forEach(key => {
    if (params[key].length === 0) return;
    let value;
    if (Array.isArray(params[key])) {
      value = JSON.stringify(params[key].map(v => (typeof v === 'string' ? v.trim() : v)));
    } else {
      value = typeof params[key] === 'string' ? params[key].trim() : params[key];
    }
    query.push([key, encodeURIComponent(value)].join('='));
  });
  return query.join('&');
};

function createConfig() {
  return {
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
  };
}
