import { ERRORS, FIELD_VALIDATION } from '@bs/techconnect-ui';

export const COLUMNS = [
  { key: 'expand', title: '' },
  { key: 'name', title: '' },
  { key: 'add', title: '' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];
export const OPTIONS_VALUES_COLUMNS = [
  { key: 'name', title: 'Название' },
  { key: 'value', title: 'Значение' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const getBody = form => {
  const { model, name, vinRegExp, brandId } = form;
  return model ? { name, vinRegExp, brandId } : { name };
};

export const SETTINGS_TABS = { p: 'params', o: 'options' };

export const MODEL_FIELDS = [
  {
    key: 'name',
    title: 'Название модели',
    options: { required: ERRORS.REQUIRED('Название модели'), validate: v => FIELD_VALIDATION.DURATION(v, 1, 30) },
  },
  {
    key: 'vinRegExp',
    title: 'VIN RegExp',
    options: { validate: v => FIELD_VALIDATION.DURATION(v, 0, 250) },
  },
];

export const INITCONFIG_FIELDS = [
  [
    { key: 'vendor', title: 'vendor' },
    {
      key: 'vehicleUsage',
      title: 'vehicleUsage',
      list: Array.from({ length: 3 }, (_, id) => ({
        id: id.toString(),
        title: id.toString(),
      })),
      options: { valueAsNumber: true },
    },
    {
      key: 'vehicleArchitecture',
      title: 'vehicleArchitecture',
      options: { valueAsNumber: true },
      type: 'number',
    },
  ],
  [
    {
      key: 'primaryHostName',
      title: 'primaryHostName',
    },
    {
      key: 'secondaryHostName',
      title: 'secondaryHostName',
    },
    {
      key: 'primaryGatewayHostName',
      title: 'primaryGatewayHostName',
    },
    {
      key: 'secondaryGatewayHostName',
      title: 'secondaryGatewayHostName',
    },
  ],
  [
    {
      key: 'drvBhvLongAccelThreshold',
      title: 'drvBhvLongAccelThreshold',
      options: { valueAsNumber: true },
      type: 'number',
    },
    {
      key: 'drvBhvLatAccelThreshold',
      title: 'drvBhvLatAccelThreshold',
      options: { valueAsNumber: true },
      type: 'number',
    },
    {
      key: 'drvBhvAltAccelThreshold',
      title: 'drvBhvAltAccelThreshold',
      options: { valueAsNumber: true },
      type: 'number',
    },
    {
      key: 'drvBhvLongDecelThreshold',
      title: 'drvBhvLongDecelThreshold',
      options: { valueAsNumber: true },
      type: 'number',
    },
    {
      key: 'drvBhvLatDecelThreshold',
      title: 'drvBhvLatDecelThreshold',
      options: { valueAsNumber: true },
      type: 'number',
    },
    {
      key: 'drvBhvAltDecelThreshold',
      title: 'drvBhvAltDecelThreshold',
      options: { valueAsNumber: true },
      type: 'number',
    },
    {
      key: 'drvBhvAccelEventMaxDuration',
      title: 'drvBhvAccelEventMaxDuration',
      options: { valueAsNumber: true },
      type: 'number',
    },
    {
      key: 'drvBhvAccelDiscret',
      title: 'drvBhvAccelDiscret',
      options: { valueAsNumber: true },
      type: 'number',
    },
    {
      key: 'drvBhvCrashAccelThreshold',
      title: 'drvBhvCrashAccelThreshold',
      options: { valueAsNumber: true },
      type: 'number',
    },
  ],
  [
    {
      key: 'alarmImpactThreshold',
      title: 'alarmImpactThreshold',
      options: { valueAsNumber: true },
      type: 'number',
    },
    {
      key: 'alarmTiltThreshold',
      title: 'alarmTiltThreshold',
      options: { valueAsNumber: true },
      type: 'number',
    },
    {
      key: 'lowBatteryVoltageThreshold',
      title: 'lowBatteryVoltageThreshold',
      type: 'number',
      options: { valueAsNumber: true },
    },
    {
      key: 'lowBatteryVoltageCancelThreshold',
      title: 'lowBatteryVoltageCancelThreshold',
      type: 'number',
      options: { valueAsNumber: true },
    },
    {
      key: 'remoteStartType',
      title: 'remoteStartType',
      list: Array.from({ length: 8 }, (_, id) => ({
        id: id.toString(),
        title: id.toString(),
      })),
      options: { valueAsNumber: true },
    },
    {
      key: 'remoteStartIgnStarterInterval',
      title: 'remoteStartIgnStarterInterval',
      type: 'number',
      options: { valueAsNumber: true },
    },
    {
      key: 'tcuInstallationAngle',
      title: 'tcuInstallationAngle',
      options: {
        validate: v => FIELD_VALIDATION.COUNT(isNaN(v) ? 0 : v, 0, 360),
        valueAsNumber: true,
      },
      type: 'number',
    },
  ],
];

export const requiredFieldKeys = [...INITCONFIG_FIELDS.flat(), ...MODEL_FIELDS]
  .filter(({ options }) => options?.required)
  .map(({ key }) => key);

const configBodyKeys = [
  'vendor',
  'vehicleUsage',
  'vehicleArchitecture',
  'remoteStartType',
  'remoteStartIgnStarterInterval',
  'tcuInstallationAngle',
];
const homeEndpointsKeys = [
  'primaryHostName',
  'primaryGatewayHostName',
  'secondaryHostName',
  'secondaryGatewayHostName',
];
const eventThresholdsKeys = [
  'drvBhvLongAccelThreshold',
  'drvBhvLatAccelThreshold',
  'drvBhvAltAccelThreshold',
  'drvBhvLongDecelThreshold',
  'drvBhvLatDecelThreshold',
  'drvBhvAltDecelThreshold',
  'drvBhvAccelEventMaxDuration',
  'drvBhvAccelDiscret',
  'drvBhvCrashAccelThreshold',
  'alarmImpactThreshold',
  'alarmTiltThreshold',
  'lowBatteryVoltageThreshold',
  'lowBatteryVoltageCancelThreshold',
];
const processConfigValues = (keys, form) => {
  const result = {};
  keys.forEach(key => (form[key] === 0 || !!form[key]) && (result[key] = form[key]));
  return result;
};
export const getFormData = form => {
  const { name, vinRegExp } = form;
  const modelBody = { name, vinRegExp };
  const initConfigBody = { ...processConfigValues(configBodyKeys, form) };
  const homeEndpointsValues = processConfigValues(homeEndpointsKeys, form);
  if (Object.keys(homeEndpointsValues).length) initConfigBody.homeEndpointsValues = homeEndpointsValues;
  const eventThresholds = processConfigValues(eventThresholdsKeys, form);
  if (Object.keys(eventThresholds).length) initConfigBody.eventThresholds = eventThresholds;

  return { modelBody, initConfigBody };
};
