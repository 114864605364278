import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { modelBrandService, optionValuesService, vehicleService } from 'services';
import { useSuccess } from 'helpers';
import { INFO_FIELDS, processVehicle } from '../helpers';
import { OPTIONS_VALUES_COLUMNS } from 'components/BrandsModels/helpers';
import {
  Loader,
  Modal,
  Table,
  SelectDate,
  Select,
  Input,
  Button,
  ButtonTypes,
  getDate,
  requestDate,
  ERRORS,
  FIELD_VALIDATION,
  Shorter,
} from '@bs/techconnect-ui';
import { DeviceSelect } from './DeviceSelect/DeviceSelect';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { OptionsValuesForm } from 'components/BrandsModels/OptionsValuesForm/OptionsValuesForm';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { ReactComponent as Close } from 'icons/cross.svg';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import styles from './VehicleEdit.module.css';

export const VehicleEdit = ({ vehicleId, setOpen }) => {
  const { setSuccess } = useSuccess();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [exitModal, setExitModal] = useState(false);
  const { vin, plateNumber, model, device, info = {} } = data;
  const { deviceId } = device || {};

  const fetchVehicle = useCallback(() => {
    setLoading(true);
    vehicleService
      .getVehicle(vehicleId)
      .then(({ data, status }) => {
        if (status === 200 && data) setData(processVehicle(data));
      })
      .finally(() => setLoading(false));
  }, [vehicleId]);
  useEffect(() => {
    if (vehicleId) fetchVehicle();
  }, [fetchVehicle, vehicleId]);

  //DEVICE
  const [deviceModal, setDeviceModal] = useState(false);
  const [removeDeviceModal, setRemoveDeviceModal] = useState(false);

  //BRANDS MODELS
  const [loadingModels, setLoadingModels] = useState(false);
  const [models, setModels] = useState([]);
  const fetchModels = useCallback(() => {
    setLoadingModels(true);
    modelBrandService
      .getModels()
      .then(({ data, status }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setModels(data.items.map(m => ({ ...m, title: [m?.brand?.name, m?.name].join(' ') })));
        }
      })
      .finally(() => setLoadingModels(false));
  }, []);
  useEffect(() => {
    fetchModels();
  }, [fetchModels]);

  //FORM
  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: 'onTouched' });
  const [loadingForm, setLoadingForm] = useState(false);
  useEffect(() => {
    const saleDate = getDate(data?.saleDate);
    reset({ vin, plateNumber, modelId: model?.id, deviceId, saleDate });
  }, [deviceId, model?.id, plateNumber, reset, data.saleDate, vin]);

  //OPTION VALUES
  const [optionsValues, setOptionsValues] = useState([]);
  const [loadingOptionsValues, setLoadingOptionsValues] = useState(false);
  const [optionValueForm, setOptionValueForm] = useState(false);
  const [deleteOptionValuesModal, setDeleteOptionValuesModal] = useState(false);
  const handlerOptionsValues = row => ({
    ...row,
    name: row?.option?.name,
    edit: (
      <Button variant={ButtonTypes.TL} onClick={() => setOptionValueForm(row)}>
        <Edit />
      </Button>
    ),
    delete: (
      <Button variant={ButtonTypes.TL} onClick={() => setDeleteOptionValuesModal(row.id)}>
        <Delete />
      </Button>
    ),
  });
  const fetchOptionsValues = useCallback(() => {
    setLoadingOptionsValues(true);
    vehicleService
      .getVehicleOptionValues(vehicleId)
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setOptionsValues(data?.items);
        }
      })
      .finally(() => setLoadingOptionsValues(false));
  }, [vehicleId]);
  useEffect(() => {
    if (vehicleId) fetchOptionsValues();
  }, [vehicleId, fetchOptionsValues]);

  const submit = form => {
    const body = { ...form, saleDate: requestDate({ date: form.saleDate, tz: '+00:00' }) || null };
    delete body.modelId;
    setLoadingForm(true);
    const promises = [vehicleService.updateVehicle(body, vehicleId)];
    if (form.modelId !== model.id) {
      promises.push(vehicleService.updateVehicleModel({ modelId: form.modelId }, vehicleId));
    }
    Promise.all(promises)
      .then(results => {
        if (results.every(result => result.status === 200)) {
          setSuccess(`Изменения сохранены`);
          fetchVehicle();
          close();
        }
      })
      .finally(() => setLoadingForm(false));
  };
  const close = () => {
    reset({});
    setOpen();
    setExitModal(false);
  };

  return (
    <Modal open={!!vehicleId} className={styles['wrapper']}>
      <Button variant={ButtonTypes.T} className={styles['close']} onClick={isDirty ? () => setExitModal(true) : close}>
        <Close />
      </Button>
      <header className={styles['header']}>
        <h3>Редактирование</h3>
        <div>
          <span className={styles['vehicleId']}>VehicleID: </span>
          <span>{vehicleId}</span>
        </div>
      </header>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className={styles['data']}>
            {Object.keys(INFO_FIELDS).map(key => (
              <div className={styles['data-field']} key={key}>
                <div className={styles['data-title']}>{INFO_FIELDS[key]}</div>
                <Shorter length={120}>{info[key]}</Shorter>
              </div>
            ))}
          </div>

          <div className={styles['actions']}>
            <Button variant={ButtonTypes.SR} className={styles['header-action']} onClick={() => setDeviceModal(true)}>
              {deviceId ? 'Сменить' : 'Привязать'} устройство
            </Button>
            {deviceId && (
              <Button
                variant={ButtonTypes.SR}
                className={styles['header-action']}
                onClick={() => setRemoveDeviceModal(true)}
              >
                Отвязать устройство
              </Button>
            )}
            <DeviceSelect
              vehicle={data}
              open={deviceModal}
              setOpen={() => setDeviceModal(!deviceModal)}
              update={fetchVehicle}
            />
            <ConfirmModal
              open={!!removeDeviceModal}
              setOpen={() => setRemoveDeviceModal(!removeDeviceModal)}
              updateList={fetchVehicle}
              method={() =>
                vehicleService.updateVehicle({ vin, plateNumber, modelId: model?.id, deviceId: null }, vehicleId)
              }
              header="Отвязывать устройство?"
              description="Я понимаю, что отвязываю устройство от транспортного средства"
              submit="Отвязать"
            />
          </div>

          {/* ФОРМА */}
          <div className={styles['form']}>
            <Controller
              control={control}
              name="modelId"
              rules={{ required: ERRORS.REQUIRED('Модель') }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Select
                  className={styles['brandModel']}
                  placeholder="Модель"
                  list={models}
                  selected={value}
                  onSelect={onChange}
                  onBlur={onBlur}
                  error={error}
                  disabled={loadingModels || loadingForm}
                />
              )}
            />
            <Input
              placeholder="VIN"
              className={styles['vin']}
              register={register('vin', {
                required: ERRORS.REQUIRED('VIN'),
                validate: v => FIELD_VALIDATION.LENGTH(v, 17),
              })}
              error={errors?.vin}
              disabled={loadingForm}
            />
            <Input
              placeholder="Гос. номер"
              className={styles['plateNumber']}
              register={register('plateNumber', { validate: v => FIELD_VALIDATION.DURATION(v, 0, 10) })}
              error={errors?.plateNumber}
              disabled={loadingForm}
            />
            <Controller
              control={control}
              name="saleDate"
              render={({ field: { onChange, value } }) => (
                <SelectDate placeholder="Дата продажи" className={styles['saleDate']} date={value} setDate={onChange} />
              )}
            />
            <Button
              className={styles['submit']}
              disabled={!isValid || loadingForm}
              onClick={() => handleSubmit(submit)()}
            >
              Сохранить
            </Button>
          </div>

          {/* ОПЦИИ */}
          <div className={styles['options']}>
            <header>
              Опции
              <Button variant={ButtonTypes.R} onClick={() => setOptionValueForm({})}>
                Добавить
              </Button>
            </header>

            {loadingOptionsValues && <Loader />}
            {!loadingOptionsValues && (
              <Table
                idKey="id"
                className={styles['options-values-table']}
                columns={OPTIONS_VALUES_COLUMNS}
                handler={handlerOptionsValues}
                loading={loading}
                rows={optionsValues}
              />
            )}

            <OptionsValuesForm
              itemId={vehicleId}
              optionValue={optionValueForm}
              open={!!optionValueForm}
              createMethod={vehicleService.createVehicleOptionValue}
              setOpen={() => setOptionValueForm(false)}
              updateList={fetchOptionsValues}
            />

            <ConfirmModal
              open={!!deleteOptionValuesModal}
              setOpen={() => setDeleteOptionValuesModal(!deleteOptionValuesModal)}
              updateList={fetchOptionsValues}
              method={() => optionValuesService.deleteOptionValue(deleteOptionValuesModal)}
              header="Удалить значение опции?"
              description="Я понимаю, что восстановить значение опции будет невозможно"
            />
          </div>

          <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={close} />
        </>
      )}
    </Modal>
  );
};

VehicleEdit.defaultProps = {
  vehicleId: false,
  setOpen: () => {},
};

VehicleEdit.propTypes = {
  vehicleId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  setOpen: PropTypes.func,
};
