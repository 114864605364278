import { FilterTypes } from '@bs/techconnect-ui';

export const AG_TYPES = {
  Dealer: 'Dealer',
  Owner: 'Owner',
};

export const FILTERS = [
  { key: 'name', title: 'Название' },
  {
    key: 'rights',
    title: 'Тип',
    type: FilterTypes.s,
    list: Object.keys(AG_TYPES).map(id => ({ id, title: AG_TYPES[id] })),
  },
];

export const COLUMNS = [
  { key: 'name', title: 'Название' },
  { key: 'rights', title: 'Тип' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const AG_FORM_TABS = { v: 'vehiclesTab', u: 'usersTab' };

export const USER_COLUMNS = [
  { key: 'action', title: '' },
  { key: 'fio', title: 'ФИО' },
  { key: 'phone', title: 'Телефон' },
  { key: 'email', title: 'E-mail' },
];
