import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { deviceService } from 'services';
import { DEVICE_FILTERS, DEVICE_COLUMNS } from 'components/Vehicles/helpers';
import { Table, TableTypes, Radio, Input, Button, ButtonTypes, shortString } from '@bs/techconnect-ui';
import { ReactComponent as Search } from 'icons/search.svg';
import { ReactComponent as Clear } from 'icons/cross.svg';

import styles from '../VehicleCreate.module.css';

export const DeviceList = ({ withoutFilter, selected, setSelected }) => {
  const [filters, setFilters] = useState({});
  const [filtersInput, setFiltersInput] = useState({});

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (withoutFilter || Object.keys(filters).length) {
      setLoading(true);
      deviceService
        .getDevices({ page, pageSize: 6, ...filters })
        .then(({ status, data }) => {
          if (status === 200 && Array.isArray(data?.items)) setRows(data.items);
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        })
        .finally(() => setLoading(false));
    }
  }, [page, filters, withoutFilter]);

  const apply = () => {
    setFilters(filtersInput);
  };
  const clear = () => {
    setFiltersInput({});
    setFilters({});
  };

  const handler = row => ({
    ...row,
    action: (
      <Radio
        name="device"
        id={row.deviceId}
        value={row.deviceId}
        checked={selected === row.deviceId}
        onClick={() => setSelected('deviceId', selected === row.deviceId ? '' : row.deviceId)}
      />
    ),
    id: shortString(row.deviceId),
    esn: row.esn,
    vehicleId: shortString(row.vehicle?.vehicleId),
    status: row.status,
  });

  return (
    <div className={styles['list']}>
      <div className={styles['list-filters']}>
        {Object.keys(DEVICE_FILTERS).map(key => (
          <Input
            key={key}
            placeholder={DEVICE_FILTERS[key]}
            value={filtersInput[key] || ''}
            onChange={({ target }) => setFiltersInput({ ...filtersInput, [key]: target.value })}
          />
        ))}
        <Button variant={ButtonTypes.S} onClick={apply}>
          <Search />
        </Button>
      </div>

      <div className={styles['list-filters']}>
        <Button variant={ButtonTypes.T} onClick={clear} disabled={!Object.keys(filters).length}>
          Сбросить фильтр&nbsp;
          <Clear />
        </Button>
      </div>

      {(!!Object.keys(filters).length || withoutFilter) && (
        <Table
          variant={TableTypes.S}
          className={styles['list-table']}
          columns={DEVICE_COLUMNS}
          loading={loading}
          rows={rows}
          totalPages={totalPages}
          setPage={setPage}
          page={page}
          idKey="deviceId"
          handler={handler}
        />
      )}
    </div>
  );
};

DeviceList.defaultProps = {
  withoutFilter: false,
  selected: '',
  setSelected: () => {},
};

DeviceList.propTypes = {
  withoutFilter: PropTypes.bool,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
};
