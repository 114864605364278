import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { accessGroupService } from 'services';
import { AG_FORM_TABS, AG_TYPES } from '../helpers';
import { useSuccess } from 'helpers';
import {
  Modal,
  Select,
  Input,
  ButtonGroup,
  Button,
  ButtonTypes,
  ERRORS,
  updateFormErrors,
  FIELD_VALIDATION,
} from '@bs/techconnect-ui';
import { VehicleList } from './VehicleList/VehicleList';
import { UserList } from './UserList/UserList';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { ReactComponent as Close } from 'icons/cross.svg';

import styles from './AccessGroupsCreate.module.css';

export const AccessGroupsCreate = ({ open, setOpen, accessGroup, updateList }) => {
  const { setSuccess } = useSuccess();

  const [tab, setTab] = useState(AG_FORM_TABS.v);
  const [loading, setLoading] = useState(false);
  const [vehiclesAGCount, setVehiclesAGCount] = useState(0);
  const [usersAGCount, setUsersAGCount] = useState(0);
  const [exitModal, setExitModal] = useState(false);

  const {
    control,
    reset,
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setError,
    watch,
  } = useForm({ mode: 'onTouched' });
  const id = watch('id');

  useEffect(() => {
    if (accessGroup) reset(accessGroup);
  }, [accessGroup, reset]);

  const submit = form => {
    const { name } = form;
    setLoading(true);
    const method = id ? accessGroupService.updateAccessGroup({ name }, id) : accessGroupService.createAccessGroup(form);
    method
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          setSuccess(`${id ? 'Изменения сохранены' : 'Группа доступа создана'} `);
          reset({ ...form, id: data.id });
          close();
        }
      })
      .finally(() => setLoading(false));
  };

  const close = () => {
    reset({});
    updateList();
    setLoading(false);
    setOpen();
    setExitModal(false);
  };

  return (
    <Modal open={open} className={styles[id ? 'wrapper-full' : 'wrapper']}>
      <Button
        variant={ButtonTypes.T}
        className={styles['close']}
        disabled={loading}
        onClick={isDirty ? () => setExitModal(true) : close}
      >
        <Close />
      </Button>
      <header className={styles['header-wrapper']}>
        <div className={styles['header']}>
          <h3>{id ? 'Редактирование' : 'Создание'} группы доступа</h3>
        </div>
        {!!id && (
          <div className={styles['ag-id']}>
            <span>AccessGroupId: </span>
            <span>{id}</span>
          </div>
        )}
      </header>

      <div className={styles['params']}>
        <Input
          placeholder="Название"
          register={register('name', {
            required: ERRORS.REQUIRED('Название'),
            validate: v => FIELD_VALIDATION.DURATION(v, 1, 130),
          })}
          disabled={loading}
          error={errors.name}
        />
        <Controller
          control={control}
          name="rights"
          rules={{ required: ERRORS.REQUIRED('Тип доступа') }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <Select
              className={styles['params-field']}
              placeholder="Тип доступа"
              list={Object.keys(AG_TYPES).map(id => ({ id, title: AG_TYPES[id] }))}
              selected={value}
              onSelect={onChange}
              onBlur={onBlur}
              error={error}
              disabled={id || loading}
            />
          )}
        />
      </div>

      {!!id && (
        <div className={styles['tabs']}>
          <ButtonGroup
            buttons={[
              { key: AG_FORM_TABS.v, title: `Автомобили (${vehiclesAGCount})` },
              { key: AG_FORM_TABS.u, title: `Пользователи (${usersAGCount})` },
            ]}
            selected={[tab]}
            onSelect={setTab}
            disabled={loading}
          />
        </div>
      )}

      {id && (
        <VehicleList
          open={tab === AG_FORM_TABS.v}
          disabled={loading}
          accessGroup={id}
          vehiclesAGCount={vehiclesAGCount}
          setVehiclesAGCount={setVehiclesAGCount}
        />
      )}
      {id && (
        <UserList
          open={tab === AG_FORM_TABS.u}
          disabled={loading}
          accessGroup={id}
          usersAGCount={usersAGCount}
          setUsersAGCount={setUsersAGCount}
        />
      )}

      <footer className={styles['footer']}>
        <Button disabled={!isValid || loading} onClick={() => handleSubmit(submit)()}>
          {id ? 'Редактировать' : 'Создать'}
        </Button>
      </footer>

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={close} />
    </Modal>
  );
};

AccessGroupsCreate.defaultProps = {
  open: false,
  setOpen: () => {},
  accessGroup: {},
  updateList: () => {},
};

AccessGroupsCreate.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  accessGroup: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  updateList: PropTypes.func,
};
