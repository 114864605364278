export const COLUMNS = [
  { key: 'name', title: 'Название' },
  { key: 'protocol', title: 'Протокол' },
  { key: 'serverPort', title: 'Сервер, порт' },
  { key: 'state', title: 'Статус' },
  { key: 'startStop', title: '' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const STATE_ENUM = {
  started: 'started',
  stopped: 'stopped',
};

export const PROTOCOLS = ['WialonIPS20', 'GAZ'];
