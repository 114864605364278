import { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { deviceService } from 'services';
import { useSuccess } from 'helpers';
import { Modal, Input, Button, ButtonTypes, ERRORS, FIELD_VALIDATION, updateFormErrors } from '@bs/techconnect-ui';
import { ReactComponent as Close } from 'icons/cross.svg';

import styles from '../Devices.module.css';

export const ProvisionDevices = ({ open, setOpen, updateList }) => {
  const [loading, setLoading] = useState(false);
  const { setSuccess } = useSuccess();

  const {
    register,
    reset,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm({ mode: 'onTouched' });

  const submit = form => {
    setLoading(true);
    deviceService
      .provisioning(form)
      .then(({ status, data }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          setSuccess(`Устройство получено`);
          updateList();
        }
      })
      .finally(() => setLoading(false));
  };

  const close = () => {
    reset({});
    setOpen();
  };

  return (
    <Modal open={open} className={[styles['create-wrapper'], styles['action-wrapper']].join(' ')}>
      <header>Получение устройства</header>
      <Button variant={ButtonTypes.T} className={styles['create-close']} onClick={close}>
        <Close />
      </Button>
      <Input
        placeholder={'ESN'}
        className={styles['create-field']}
        register={register('esn', {
          required: ERRORS.REQUIRED('ESN'),
          validate: v => FIELD_VALIDATION.DURATION(v, 1, 30),
        })}
        disabled={loading}
        error={errors.esn}
      ></Input>

      <Button disabled={!isValid || loading} onClick={() => handleSubmit(submit)()}>
        Получить
      </Button>
    </Modal>
  );
};

ProvisionDevices.defaultProps = {
  open: false,
  setOpen: () => {},
  updateList: () => {},
};

ProvisionDevices.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updateList: PropTypes.func,
};
