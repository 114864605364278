import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { deviceService } from 'services';
import { COLUMNS, FILTERS } from './helpers';
import { useSuccess } from 'helpers';

import {
  Copy,
  Popper,
  Table,
  Button,
  ButtonTypes,
  ListFilters,
  parseDate,
  pageSize,
  shortString,
} from '@bs/techconnect-ui';
import { DeviceForm } from './DeviceForm/DeviceForm';
import { ProvisionDevices } from './ProvisionDevices/ProvisionDevices';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { VehicleTelemetry } from 'components/Vehicles/VehicleTelemetry/VehicleTelemetry';
import { ReactComponent as Telemetry } from 'icons/telemetry.svg';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as Export } from 'icons/provisioning.svg';

import styles from './Devices.module.css';

const Devices = () => {
  const { setSuccess } = useSuccess();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [telemetryModal, setTelemetryModal] = useState('');
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const action_items = [
    {
      key: 'exportVehicle',
      element: (
        <Popper popup={'Получение устройства'}>
          <Button variant={ButtonTypes.S} onClick={() => setOpen(!open)}>
            <Export />
          </Button>
        </Popper>
      ),
    },
  ];

  const fetchDevices = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem(`devicesFilters`) || '{}') || {};
    const { filter = false, ...queryFilter } = filterData;
    setLoading(true);
    deviceService
      .getDevices({ page, pageSize, ...(filter && queryFilter) })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(data.items);
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [page, filters]);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices, filters]);

  const handler = row => ({
    ...row,
    id: (
      <Copy value={row.deviceId} callback={() => setSuccess('Данные скопированы')}>
        {shortString(row.deviceId)}
      </Copy>
    ),
    esn: (
      <Copy value={row.esn} callback={() => setSuccess('Данные скопированы')}>
        {shortString(row.esn)}
      </Copy>
    ),
    vehicleId: (
      <Copy value={row.vehicle?.vehicleId} callback={() => setSuccess('Данные скопированы')}>
        {shortString(row.vehicle?.vehicleId)}
      </Copy>
    ),
    lastActivity: parseDate(row.state?.lastActivity),
    telemetry: (
      <Popper popup={'Телеметрия'}>
        <Button
          variant={ButtonTypes.TL}
          onClick={() => setTelemetryModal({ deviceId: row.deviceId, vehicleId: row.vehicleId })}
        >
          <Telemetry />
        </Button>
      </Popper>
    ),
    edit: (
      <Popper popup={'Редактировать'}>
        <Button variant={ButtonTypes.TL} onClick={() => setEditModal(row.deviceId)}>
          <Edit />
        </Button>
      </Popper>
    ),
    delete: (
      <Popper popup={'Удалить'}>
        <Button variant={ButtonTypes.TL} onClick={() => setDeleteModal(row.deviceId)}>
          <Delete />
        </Button>
      </Popper>
    ),
  });

  return (
    <>
      <ListFilters
        title="Устройства"
        fields={FILTERS}
        filters={filters}
        setFilters={setFilters}
        CreateModal={DeviceForm}
        updateList={fetchDevices}
        actions={action_items}
      />

      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        loading={loading}
        idKey="deviceId"
        handler={handler}
      />

      <DeviceForm
        deviceId={editModal}
        open={!!editModal}
        setOpen={() => setEditModal(!editModal)}
        updateList={fetchDevices}
      />
      <ConfirmModal
        open={deleteModal}
        setOpen={() => setDeleteModal(!deleteModal)}
        updateList={fetchDevices}
        method={() => deviceService.deleteDevice(deleteModal)}
        header="Удалить устройство?"
        description="Я понимаю, что восстановить устройство будет невозможно"
      />
      {telemetryModal && (
        <VehicleTelemetry
          deviceId={telemetryModal?.deviceId}
          vehicleId={telemetryModal?.vehicleId}
          setOpen={() => setTelemetryModal('')}
        />
      )}
      <ProvisionDevices open={open} setOpen={setOpen} />
    </>
  );
};

export default Devices;
