export const COLUMNS = [
  { key: 'name', title: 'Название' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const BRAND_MODELS_LIST = [
  { key: 'name', title: '' },
  { key: 'checkBox', title: '' },
];
