import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { modelBrandService } from 'services';
import { COLUMNS } from './helpers';

import { Loader, Popper, Table, Button, ButtonTypes, ListFilters, pageSize } from '@bs/techconnect-ui';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as Chevron } from 'icons/chevron.svg';
import { ReactComponent as Add } from 'icons/plus-s.svg';

import styles from './BrandsModels.module.css';

const BrandsModels = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [models, setModels] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingModel, setLoadingModel] = useState({});
  const [, setEditForm] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const fetchModels = useCallback(
    id => {
      if (!id) return;
      setLoadingModel({ ...loadingModel, [id]: true });
      modelBrandService
        .getModelsByBrand(id)
        .then(({ status, data }) => {
          if (status === 200 && Array.isArray(data?.items)) {
            setModels({ ...models, [id]: data.items });
          } else {
            setModels({ ...models, [id]: [] });
          }
        })
        .finally(() => setLoadingModel({ ...loadingModel, [id]: false }));
    },
    [loadingModel, models],
  );
  const fetchBrands = useCallback(
    brandId => {
      setLoading(true);
      modelBrandService
        .getBrands({ page, pageSize })
        .then(({ status, data }) => {
          if (status === 200 && Array.isArray(data?.items)) {
            setRows(data.items);
            if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
          }
        })
        .finally(() => setLoading(false));

      fetchModels(brandId);
    },
    [fetchModels, page],
  );

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  const handler = row => ({
    ...row,
    expand: row.model ? (
      ' '
    ) : (
      <Button
        variant={ButtonTypes.T}
        onClick={() =>
          Array.isArray(models[row.id]) ? setModels({ ...models, [row.id]: false }) : fetchModels(row.id)
        }
      >
        {loadingModel[row.id] ? (
          <Loader size={20} />
        ) : (
          <Chevron style={{ transform: `rotate(${Array.isArray(models[row.id]) ? 180 : 0}deg)` }} />
        )}
      </Button>
    ),
    name: <span style={{ marginLeft: row.model ? '20px' : 0 }}>{row.name}</span>,
    add: !row.model ? (
      <Popper popup={'Добавить модель'}>
        <Button variant={ButtonTypes.TL} onClick={() => navigate(`/brandsModels/brand/${row?.id}/newModel`)}>
          <Add />
        </Button>
      </Popper>
    ) : (
      ' '
    ),
    edit: (
      <Popper popup={`Редактировать ${row.model ? 'модель' : 'бренд'}`}>
        <Button
          variant={ButtonTypes.TL}
          onClick={() => navigate(`/brandsModels/${row?.model ? 'model' : 'brand'}/${row?.id}`)}
        >
          <Edit />
        </Button>
      </Popper>
    ),
    delete: (
      <Popper popup={`Удалить ${row.model ? 'модель' : 'бренд'} `}>
        <Button
          variant={ButtonTypes.TL}
          onClick={() =>
            setDeleteModal({
              id: row.id,
              title: row.model ? 'модель' : 'бренд',
              brandId: row.brandId,
              method: row.model ? modelBrandService.deleteModel : modelBrandService.deleteBrand,
            })
          }
        >
          <Delete />
        </Button>
      </Popper>
    ),
  });

  const getRows = () => {
    const result = [];
    rows.forEach(brand => {
      result.push(brand);
      Array.isArray(models[brand.id]) &&
        models[brand.id].forEach(model => {
          result.push({ model: true, brandId: brand.id, ...model });
        });
    });

    return result;
  };

  return (
    <>
      <ListFilters title="Бренды и модели" createHandler={() => navigate('/brandsModels/brand/newBrand')} />

      <div className={styles['body']}>
        <Table
          className={styles['table']}
          columns={COLUMNS}
          rows={getRows()}
          totalPages={totalPages}
          loading={loading}
          idKey="id"
          handler={handler}
        />
      </div>

      <ConfirmModal
        open={!!deleteModal?.id}
        setOpen={() => setDeleteModal(!deleteModal)}
        updateList={() => {
          fetchBrands(deleteModal?.brandId);
          setEditForm(false);
        }}
        method={() => deleteModal?.method(deleteModal?.id)}
        header={`Удалить ${deleteModal?.title} ?`}
        description={`Я понимаю, что восстановить ${deleteModal?.title} будет невозможно`}
      />
    </>
  );
};

export default BrandsModels;
