import { useState } from 'react';
import PropTypes from 'prop-types';

import { vehicleService } from 'services';
import { useSuccess } from 'helpers';
import { Modal, Button, ButtonTypes } from '@bs/techconnect-ui';
import { DeviceList } from 'components/Vehicles/VehicleCreate/DeviceList/DeviceList';
import { ReactComponent as Close } from 'icons/cross.svg';

import styles from '../VehicleEdit.module.css';

export const DeviceSelect = ({ vehicle, open, setOpen, update }) => {
  const [loading, setLoading] = useState(false);
  const [deviceId, setDeviceId] = useState(vehicle.device?.deviceId);
  const { setSuccess } = useSuccess();

  const close = () => {
    setOpen();
  };

  const save = () => {
    const { vehicleId, vin, plateNumber, model } = vehicle;
    setLoading(true);
    vehicleService
      .updateVehicle({ vin, plateNumber, modelId: model?.id, deviceId }, vehicleId)
      .then(({ status }) => {
        if (status === 200) {
          setSuccess(`Изменения сохранены`);
          close();
          update();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal open={open} className={styles['devices']}>
      <Button variant={ButtonTypes.T} className={styles['close']} onClick={close}>
        <Close />
      </Button>
      <header>
        <h3>Выберите устройство</h3>
      </header>

      <DeviceList withoutFilter={true} selected={deviceId} setSelected={(key, value) => setDeviceId(value)} />

      <footer>
        <Button disabled={!deviceId || loading} onClick={save}>
          Сохранить
        </Button>
      </footer>
    </Modal>
  );
};

DeviceSelect.defaultProps = {
  vehicle: {},
  open: false,
  setOpen: () => {},
  update: () => {},
};

DeviceSelect.propTypes = {
  vehicle: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  update: PropTypes.func,
};
